import React, { useState, useEffect } from 'react';
import './Gifts.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { IconButton, ListSubheader, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ButtonGroup from '@mui/material/ButtonGroup';
import { FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import Snackbar from '../../../common/snackbar/components/snackbar';
import LoaderCon from '../../../common/loader/containers/loader_cont';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
import billlogo from '../../../media/img/WhatsApp Image 2024-08-23 at 12.32.17 PM 1.png';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import giftstyle from '../Component/Gift.module.css'
import { GifOutlined } from '@material-ui/icons';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import { Chip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from 'react-router-dom';
function Appointment(props) {
    const [giftNamepackage, setgiftNamepackage] = useState("");
    const [packageDetailspackage, setpackageDetailspackage] = useState({
        products: [],
        services: []
    });

    const [pricepackage, setpricepackage] = useState("");
    const [selectedPackage, setSelectedPackage] = useState("");
    const [selectedPackageedit, setSelectedPackageedit] = useState("");
    const [packageDetailss, setPackageDetails] = useState(null);
    // const [selectedPackage, setSelectedPackage] = useState(null);
    const [productDetails, setProductDetails] = useState([]);
    const [serviceDetails, setServiceDetails] = useState([]);

    const handlePackageChange = (event) => {
        const selectedGiftName = event.target.value; // The name of the selected package
        const selectedItem = props.gift.view_all_gift_package.find(
            (item) => item.giftName === selectedGiftName // Match the selected gift name with the corresponding package
        );
    
        // Update the selected package and separate product and service details
        if (selectedItem) {
            setSelectedPackage(selectedItem.giftName); // Store the selected gift name
            
            // Separate product and service details
            const { products = [], services = [] } = selectedItem.packageDetails || {};
            setProductDetails(products);
            setServiceDetails(services);
        } else {
            setSelectedPackage(''); // Clear the selection
            setProductDetails([]);
            setServiceDetails([]);
        }
    };
    
    const handlePackageChangeedit = (event) => {
        const selectedId = event.target.value;
        const chosenPackage = props.gift.view_all_gift_package.find(
            (item) => item._id === selectedId
        );
        setSelectedPackageedit(chosenPackage);  // Set the full object for the selected package
        setDetailsOfPackage(chosenPackage ? chosenPackage.packageDetails.join(', ') : '');
    };








    const [open, setOpen] = React.useState(false);
    const [cmopen, setcmOpen] = useState(false);
    const [cmgiftName, setcmGiftName] = useState('');
    const [customerNumber, setCustomerNumber] = useState('');
    const [name, setName] = useState('');
    const [cmpackageDetails, setcmPackageDetails] = useState('');
    const [expDate, setExpDate] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleViewDetails = (client) => {
        setSelectedClient(client);
        setIsDialogOpen(true);
    };

    const handlecmclose = () => {
        setcmOpen(false);
        setcmGiftName('');
        setCustomerNumber('');
        setName('');
        setcmPackageDetails('');
        setExpDate('');
        setCouponCode('');
    };
    const [inputValue, setInputValue] = useState('');
    const [values, setValues] = useState([]);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSubmitinput = (e) => {
        e.preventDefault();
        const newValues = inputValue.split(',').map(value => value.trim());
        setValues(newValues);
        setInputValue(''); // Clear the input field
    };
    const [errors, setErrors] = useState({});

    const [client, setclient] = useState('');
    const [activeTable, setActiveTable] = useState('package');
    const [giftopen, setgiftopen] = useState(false);
    // const [giftcusto, setgiftcusto] = useState(false);
    const [giftName, setgiftName] = useState("");
    const [packageDetails, setpackageDetails] = useState([]);
    const [price, setprice] = useState("");
    const [id, setId] = useState("");
    const [giftcutoopen, setgiftcutoopen] = useState(false);
    const [deltegift, setdeltegift] = useState(false);
    const [deltecustom, setdeltecustom] = useState(false);
    const [opencmedit, setopencmedit] = useState(false);
    // edit state for customers
    const [selectedGiftName, setSelectedGiftName] = useState('');
    const [customerID, setCustomerID] = useState('');
    const [customerFullName, setCustomerFullName] = useState('');
    const [detailsOfPackage, setDetailsOfPackage] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [custophn, setcustophn] = useState('');
    const [cuponUpdate, setcuponUpdate] = useState('');
    // bill
    const [isBillDialogOpen, setBillDialogOpen] = useState(false);
    const handleCloseBillDialog = () => {
        setBillDialogOpen(false);
        setDiscount(0);
    };

    const handleSelectionChange = (event) => {
        const selectedValues = event.target.value; // Get the selected items

        // Create a new object to store selected products and services
        const selectedPackageDetails = {
            products: [],
            services: [],
        };

        // Combine services and products from props into one array for processing
        const allItems = [...props.viewallservices.view_all_service, ...props.viewallservices.view_all_Products];

        // Calculate the total price for the selected items
        let totalPrice = 0;

        selectedValues.forEach((item) => {
            allItems.forEach((row) => {
                // Check if the item is a service and add it to the services array
                if (Array.isArray(row.service) && row.service.includes(item)) {
                    selectedPackageDetails.services.push(item);
                    totalPrice += row.price;
                }

                // Check if the item is a product and add it to the products array
                if (Array.isArray(row.product) && row.product.includes(item)) {
                    selectedPackageDetails.products.push(item);
                    totalPrice += row.price;
                }
            });
        });

        // Store the selected services and products in the state
        setpackageDetailspackage(selectedPackageDetails);
        console.log(selectedPackageDetails)

        // Update the total price in the state
        setpricepackage(totalPrice);
    };
    console.log(props.gift.view_all_gift_customer)

    const handleSelectionChangeedit = (event) => {
        const selectedValues = event.target.value;  // Get selected values from the Select component
        setPackageDetails(selectedValues);  // Update the state with the selected items

        // Recalculate the total price based on selected services/products
        let totalPrice = 0;
        selectedValues.forEach((item) => {
            // Check for matching services and their prices
            props.viewallservices.view_all_service.forEach((row) => {
                if (Array.isArray(row.service)) {
                    row.service.forEach((service) => {
                        if (service === item) {
                            totalPrice += row.price;
                        }
                    });
                }
            });

            // Check for matching products and their prices
            props.viewallservices.view_all_Products.forEach((row) => {
                if (Array.isArray(row.product)) {
                    row.product.forEach((product) => {
                        if (product === item) {
                            totalPrice += row.price;
                        }
                    });
                }
            });
        });

        setprice(totalPrice);  // Update the total price
    };


    const gifthandleclick = () => {
        setdeltegift(true);
    }
    const handlegiftclose = () => {
        setdeltegift(false);
    }
    const gifthandle = () => {
        setgiftopen(!giftopen);
    }
    const giftcutohandle = () => {
        setgiftcutoopen(!giftcutoopen)
    }
    const [deltegiftcustomer, setdeltegiftcustomer] = useState(false);
    const giftcustomerhandleclick = () => {
        setdeltegiftcustomer(true);
    }
    const handlegiftcustomerclose = () => {
        setdeltegiftcustomer(false);
    }
    const giftcustomerhandle = () => {
        setgiftopen(!giftopen);
    }
    const handleChangeclient = (event) => {
        setclient(event.target.value);
    };

    const [formData, setFormData] = useState({
        client: '',
        name: '',
        number: '',
        service: '',
        employee: '',
        date: '',
        time: '',
        dob: '',
        message: '',
    });

    const handleChangedata = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
    };

    const [selectedItem, setSelectedItem] = useState('');

    const handleSelectChange = (event) => {
        setSelectedItem(event.target.value);
    };

    useEffect(() => {
        props.viewAllGiftPackage(props.login.token);
        props.viewAllGiftPackageforcutomer(props.login.token);
        props.View_All_Service(props.login.token);
        props.View_All_Products(props.login.token);
        return () => { };
    }, []);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleDialogcmedClose = () => {
        setopencmedit(false);
    };
    const [select, setselect] = useState('');
    const handleChange = (event) => {
        setselect(event.target.value);
    };

    const [service, setservice] = useState('');
    const handleChangetwo = (event) => {
        setservice(event.target.value);
    };
    const [discount, setDiscount] = useState(0);  // State to store the discount
    const [finalPrice, setFinalPrice] = useState(price);  // State to store the final price after discount
    const [isPreviewDialogOpen, setPreviewDialogOpen] = useState(false); // Preview dialog state

    const handleDiscountChange = (e) => {
        const discountValue = e.target.value;
        setDiscount(discountValue);

        const discountNumber = parseFloat(discountValue) || 0;
        const priceNumber = parseFloat(price) || 0;

        const discountedPrice = priceNumber - (priceNumber * discountNumber / 100);
        setFinalPrice(discountedPrice);
    };

    const discountNumber = parseFloat(discount) || 0;
    const priceNumber = parseFloat(price) || 0;

    const discountAmount = (priceNumber * discountNumber) / 100;
    const totalAmount = priceNumber - discountAmount;


    // Function to open preview dialog
    const handlePreviewClick = () => {
        setPreviewDialogOpen(true);
        // setDiscount("");

    };

    // const getButtonLabel = () => {
    //     return activeTable === 'package' ? 'Gift Package' : 'Gift Customer';
    // };
    // const validate = () => {
    //     const errors = {};
    //     if (!cmgiftName) errors.cmgiftName = 'Gift Name is required.';
    //     if (!/^\d{10}$/.test(customerNumber)) errors.customerNumber = 'Customer Number must be exactly 10 digits.';
    //     if (!name) errors.name = 'Customer Name is required.';
    //     if (!cmpackageDetails) errors.cmpackageDetails = 'Package Details are required.';
    //     if (!expDate) errors.expDate = 'Expiration Date is required.';
    //     if (!couponCode) errors.couponCode = 'Coupon Code is required.';
    //     setErrors(errors);
    //     return Object.keys(errors).length === 0;
    // };

    const handlecmSubmit = () => {
        // if (validate()) {
        props.Add_Gift_Customer(selectedPackage, customerNumber, name, packageDetailss, expDate, couponCode, props.login.token);
        handleCloseDialog();
        // }
    };

    const handleCloseDialog = () => {
        // Clear form fields and errors
        setcmGiftName('');
        setCustomerNumber('');
        setName('');
        setcmPackageDetails('');
        setExpDate('');
        setCouponCode('');
        setErrors({});
        handlecmclose();
        setopencmedit(false)
    };

    //   edit validation
    const validateedit = () => {
        const errors = {};
        if (!selectedPackageedit.giftName) errors.selectedPackageedit.giftName = 'Gift Name is required.';
        if (!/^\d{10}$/.test(custophn)) errors.custophn = 'Number must be exactly 10 digits.';
        if (!customerFullName) errors.customerFullName = 'Customer Full Name is required.';
        if (!selectedPackageedit.packageDetails) errors.selectedPackageedit.packageDetails = 'Package Details are required.';
        if (!expirationDate) errors.expirationDate = 'Expiration Date is required.';
        if (!cuponUpdate) errors.cuponUpdate = 'Customer ID for Update is required.';
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleeditUpdate = () => {
        if (validateedit()) {
            props.Update_All_Gift_Customer(
                selectedPackageedit.giftName,
                custophn,
                customerFullName,
                selectedPackageedit.packageDetails,
                expirationDate,
                cuponUpdate,
                customerID,
                props.login.token
            );
            // console.log(selectedPackageedit.giftName,
            //     custophn,
            //     customerFullName,
            //     selectedPackageedit.packageDetails,
            //     expirationDate,
            //     cuponUpdate,
            //     customerID,
            //     props.login.token)
            handleeditCloseDialog();
        }
    };

    const handleeditCloseDialog = () => {
        // Clear form fields and errors
        setSelectedGiftName('');
        setcustophn('');
        setCustomerFullName('');
        setDetailsOfPackage('');
        setExpirationDate('');
        setcuponUpdate('');
        setCustomerID('');
        setErrors({});
        handleDialogcmedClose(); // Assume this function is passed to handle closing the dialog
    };
    const handleaddgiftSave = (e) => {
        e.preventDefault(); // Prevent the default form submission

        if (giftNamepackage && packageDetailspackage && pricepackage) {
            props.Add_Gift_package(giftNamepackage, packageDetailspackage, pricepackage, props.login.token);
            setpricepackage('');
            setgiftNamepackage('');
            setpackageDetailspackage([]);
            setIsModalOpen(false);
            toast.success("Gift package saved successfully!");
        } else {
            toast.error("Please fill out all fields.");
        }
    };
    const handleeditgiftSave = () => {
        if (giftName && packageDetails && price) {
            props.Update_All_Gift_package(giftName, packageDetails, price, id, props.login.token);
            setgiftName('');
            setprice('');
            setpackageDetails([]);
            setgiftopen(false);
            toast.success("Gift package updated successfully!");
        } else {
            toast.error("Please fill out all fields.");
        }
    };

    return (
        <div className='surround'>
            <div>
                <div className='appointment-text'>
                    <h1>Gifts</h1>

                </div>
            </div>
            <div className='side'>
                <div className='btn-grp'>
                    <Button
                        className={`side-btn ${activeTable === 'package' ? 'active3' : ''}`}
                        onClick={() => setActiveTable('package')}
                    >
                        <span className='gift-icons'><CardGiftcardIcon /></span>
                        Gift Packages
                    </Button><br></br>
                    <Button
                        className={`side-btn ${activeTable === 'customer' ? 'active3' : ''}`}
                        onClick={() => {

                            setActiveTable('customer')
                        }}
                    >
                        <span className='gift-icons'><EmojiPeopleIcon /></span>
                        Gift for customer
                    </Button>
                </div>
                {/* table start */}
                {activeTable === 'package' && (
                    <div className='appointment-main' id='table1' style={{ maxWidth: "900px" }}>
                        <div className='appointment-button'>
                            <Button className="appointmentopen-modal" onClick={handleOpenModal}>
                                {/* {getButtonLabel()} */}
                                Add  Gift Package
                            </Button>
                        </div>
                        <div className="table-container2">
                            <table className="styled-table">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Gift  Name</th>
                                        <th>Package Details</th>
                                        <th>Price</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.gift.view_all_gift_package.map((row, index) => (
                                        <tr key={index + 1}>
                                            <td>{index + 1}</td>
                                            <td>{row.giftName}</td>
                                            <td>
                                                {Array.isArray(row.packageDetails.products) && row.packageDetails.products.length > 0 && (
                                                    <div>
                                                        <strong>Products:</strong>
                                                        {row.packageDetails.products.map((product, i) => (
                                                            <button key={i} className="product-item">
                                                                {product}
                                                            </button>
                                                        ))}
                                                    </div>
                                                )}

                                                {Array.isArray(row.packageDetails.services) && row.packageDetails.services.length > 0 && (
                                                    <div>
                                                        <strong>Services:</strong>
                                                        {row.packageDetails.services.map((service, i) => (
                                                            <button key={i} className="service-item">
                                                                {service}
                                                            </button>
                                                        ))}
                                                    </div>
                                                )}
                                            </td>

                                            <td>{row.price}</td>
                                            <td>
                                                <FaEdit
                                                    className="action-icon"
                                                    style={{ color: 'green' }}
                                                    onClick={() => {
                                                        gifthandle();  // Open the dialog for editing
                                                        setId(row._id);  // Set the selected package ID
                                                        setSelectedPackageedit(row.giftName);  // Set the gift name for editing
                                                        setprice(row.price);  // Set the price of the package
                                                        setPackageDetails(row.packageDetails);  // Set the package details (multi-select)
                                                    }}
                                                />

                                                <FaTrashAlt className="action-icon" style={{ color: 'red' }} onClick={() => {
                                                    setId(row._id)
                                                    setdeltegift(true);
                                                }} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                {activeTable === 'customer' && (
                    <div className='appointment-main'>
                        <div className='appointment-button'>
                            <Button className="appointmentopen-modal" onClick={() => { setcmOpen(true) }}>
                                {/* {getButtonLabel()} */}
                                Add  Gifts
                            </Button>
                        </div>
                        <div className="table-container2">
                            <table className="styled-table">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th style={{ whiteSpace: "nowrap" }}>Gift Package Name</th>
                                        <th>Phone</th>
                                        <th style={{ whiteSpace: "nowrap" }}>Customer Name</th>

                                        {/* <th style={{ whiteSpace: "nowrap" }}>Package Details</th> */}
                                        {/* <th style={{ whiteSpace: "nowrap" }}>Bill Details</th> */}
                                        {/* <th style={{ whiteSpace: "nowrap" }}>Cupon Code</th> */}
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.gift.view_all_gift_customer.map((custo, index) => (
                                            <tr key={index + 1}>
                                                <td>{index + 1}</td>
                                                <td style={{ whiteSpace: "nowrap" }}>{custo.giftName}</td>
                                                <td>{custo.customerNumber}</td>
                                                <td>{custo.name}</td>

                                                {/* <td>{Array.isArray(custo.packageDetails) ? (
                                                    custo.packageDetails.map((packageDetails, i) => (
                                                        <button key={i} className="service-item" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                                                            {packageDetails}{" "}
                                                        </button>
                                                    ))
                                                ) : (
                                                    custo.packageDetails
                                                )}</td> */}
                                                {/* <td>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => {
                                                            const selectedPackage = props.gift.view_all_gift_package.find(
                                                                (item) => item.giftName === custo.giftName
                                                            );
                                                            if (selectedPackage) {
                                                                setSelectedPackageedit(selectedPackage);
                                                                setprice(selectedPackage.price); 
                                                            }
                                                            setBillDialogOpen(true);
                                                            setCustomerFullName(custo.name);
                                                            setcustophn(custo.customerNumber);
                                                        }}
                                                        style={{ backgroundColor: '#607d8b', color: '#fff' }}
                                                    >
                                                        Generate Bill
                                                    </Button>
                                                </td>                                                <td>{custo.couponCode}</td> */}
                                                <td>
                                                    <FaEye className="action-icon" onClick={() => {

                                                        setSelectedPackageedit(custo.giftName)
                                                        setCustomerFullName(custo.name)
                                                        setcustophn(custo.customerNumber)
                                                        // setDetailsOfPackage(custo.packageDetails)
                                                        console.log(custo.packageDetails)
                                                        setExpirationDate(custo.expDate)
                                                        setcuponUpdate(custo.couponCode)
                                                        setIsDialogOpen(true);

                                                    }} style={{ color: 'purple' }} />
                                                    <FaEdit
                                                        className="action-icon"
                                                        style={{ color: 'green' }}
                                                        onClick={() => {
                                                            setCustomerID(custo._id);
                                                            const selectedPackage = props.gift.view_all_gift_package.find(
                                                                (item) => item.giftName === custo.giftName
                                                            );
                                                            setSelectedPackageedit(selectedPackage);
                                                            setCustomerFullName(custo.name);
                                                            setcustophn(custo.customerNumber);
                                                            setDetailsOfPackage(custo.packageDetails);
                                                            setExpirationDate(custo.expDate);
                                                            setcuponUpdate(custo.couponCode);
                                                            setopencmedit(true);
                                                        }}
                                                    />

                                                    <FaTrashAlt className="action-icon" style={{ color: 'red' }} onClick={() => {
                                                        setId(custo._id);
                                                        setdeltecustom(true)
                                                    }} />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
            {/* table end */}
            {/* gift edit */}
            <Dialog open={giftopen} onClose={gifthandle} maxWidth="sm" fullWidth>
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Edit Gift Packages
                    <IconButton onClick={() => setgiftopen(false)} style={{ fontSize: '25px', color: "#6A8C9F" }}>&times;</IconButton>
                </DialogTitle>
                <Divider className='appointment-line' sx={{ color: '#698c9f' }} />
                <DialogContent>
                    <Grid container spacing={2} className="form-container">
                        <Grid item xs={12}>
                            <TextField
                                label="Gift Name"
                                fullWidth
                                variant="outlined"
                                value={selectedPackageedit}
                                onChange={(e) => setgiftName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>

                            {/* onChange={(e) => setpackageDetails(e.target.value)} */}
                            <Select
                                fullWidth
                                multiple
                                value={packageDetailss}  // Bind to selected package details
                                onChange={handleSelectionChangeedit}  // Handle selection changes
                                renderValue={(selected) => (
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} style={{ margin: 2 }} />  // Render the selected items as chips
                                        ))}
                                    </div>
                                )}
                            >
                                {/* Services group */}
                                <ListSubheader>Services</ListSubheader>
                                {Array.isArray(props.viewallservices.view_all_service) &&
                                    props.viewallservices.view_all_service.map((row) => (
                                        Array.isArray(row.service) &&
                                        row.service.map((service, i) => (
                                            <MenuItem key={`service-${row._id}-${i}`} value={service}>
                                                {service} {/* Display the service name */}
                                            </MenuItem>
                                        ))
                                    ))}

                                {/* Products group */}
                                <ListSubheader>Products</ListSubheader>
                                {Array.isArray(props.viewallservices.view_all_Products) &&
                                    props.viewallservices.view_all_Products.map((row) => (
                                        Array.isArray(row.product) &&
                                        row.product.map((product, i) => (
                                            <MenuItem key={`product-${row._id}-${i}`} value={product}>
                                                {product} {/* Display the product name */}
                                            </MenuItem>
                                        ))
                                    ))}
                            </Select>

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Price"
                                fullWidth
                                variant="outlined"
                                value={price}
                                // onChange={(e) => setprice(e.target.value)}
                                disabled
                                placeholder="e.g., value1, value2, value3"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleeditgiftSave}
                        color="primary"
                        variant="contained"
                        style={{ padding: '10px', fontSize: '16px', fontWeight: 'bold', width: '20%', background: '#607d8b' }}
                    >
                        Save Chnages
                    </Button>
                </DialogActions>
            </Dialog>
            {/* modal */}
            <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth="sm" fullWidth>
                <div className="appointment-overlay">
                    <div className="appointment-content">
                        <button className="appointment-close" onClick={handleCloseModal} style={{ fontSize: '34px' }} >
                            &times;
                        </button>
                        <h2>Add Gift Packages</h2>
                        <hr className='appointment-line' />
                        <div className="form-container">
                            <form onSubmit={handleSubmitinput}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div className="form-group">
                                        <label>Gift Name</label>
                                        <input
                                            type="text"
                                            name="number"
                                            placeholder="Enter Gift Name"
                                            value={giftNamepackage}
                                            onChange={(e) => setgiftNamepackage(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Services / Product</label>

                                        <Select
                                            multiple
                                            value={[...(packageDetailspackage.products || []), ...(packageDetailspackage.services || [])]} // Ensure both are arrays
                                            onChange={handleSelectionChange}
                                            renderValue={(selected) => (
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={value} style={{ margin: 2 }} />
                                                    ))}
                                                </div>
                                            )}
                                        >
                                            {/* Group for Services */}
                                            <ListSubheader style={{ fontSize: "30px", color: "#000" }}>Services</ListSubheader>
                                            {Array.isArray(props.viewallservices.view_all_service) &&
                                                props.viewallservices.view_all_service.map((row, index) => (
                                                    Array.isArray(row.service) &&
                                                    row.service.map((service, i) => (
                                                        <MenuItem key={`service-${row._id}-${i}`} value={service}>
                                                            {service}  {/* Show the service */}
                                                        </MenuItem>
                                                    ))
                                                ))
                                            }

                                            {/* Group for Products */}
                                            <ListSubheader style={{ fontSize: "30px", color: "#000" }}>Products</ListSubheader>
                                            {Array.isArray(props.viewallservices.view_all_Products) &&
                                                props.viewallservices.view_all_Products.map((row) => (
                                                    Array.isArray(row.product) &&
                                                    row.product.map((product, i) => (
                                                        <MenuItem key={`product-${row._id}-${i}`} value={product}>
                                                            {product}  {/* Show the product */}
                                                        </MenuItem>
                                                    ))
                                                ))
                                            }
                                        </Select>

                                        {/* <label>Add Product / service</label>
                                        <input
                                            type="text"
                                            id="multiData"
                                            value={packageDetailspackage}
                                            onChange={(e) => 
                                                (e.target.value)}
                                            placeholder="e.g., value1, value2, value3"
                                            className="inputField"
                                            required
                                        /> */}
                                    </div>
                                    <div className="form-group">
                                        <label>Price</label>
                                        <input
                                            type="text"
                                            value={pricepackage}
                                            // onChange={(e) => setpricepackage(e.target.value)}
                                            disabled
                                            placeholder="Enter price"
                                            className="inputField"
                                            required
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="appointment-form-button">
                                    <button
                                        type="submit"
                                        onClick={handleaddgiftSave}
                                    >
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Dialog>
            {/* delete gift */}
            <Dialog
                open={deltegift}
                onClose={() => {
                    setdeltegift(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to Delete Gift?"}
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description">
                        Are you Delete Gift
                    </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setdeltegift(false)
                    }} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        props.Delete_all_giftpackage(id, props.login.token);
                        setdeltegift(false)
                    }} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            {/* delete gift customer */}
            <Dialog
                open={deltegiftcustomer}
                onClose={() => {
                    setdeltegiftcustomer(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to Delete Gift?"}
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description">
                        Are you Delete Gift
                    </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setdeltegiftcustomer(false)
                    }} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        props.Delete_all_giftCustomer(id, props.login.token);
                        setdeltegiftcustomer(false)
                    }} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            {/* add gift customer dialog */}
            <Dialog open={cmopen} onClose={handlecmclose} maxWidth="sm" fullWidth>
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Add Gift Customer
                    <Button onClick={handlecmclose} style={{ color: '#607d8b', fontSize: '34px' }}>&times;</Button>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                        <Select
    labelId="select-package-label"
    value={selectedPackage} // The value is the selected package name
    onChange={handlePackageChange} // Calls the handlePackageChange function on selection
    fullWidth
    variant="outlined"
>
    <MenuItem value="">Select Package</MenuItem>
    {props.gift.view_all_gift_package.map((giftitem) => (
        <MenuItem key={giftitem._id} value={giftitem.giftName}>
            {giftitem.giftName} {/* This is the displayed text */}
        </MenuItem>
    ))}
</Select>

                            {errors.selectedPackage && <FormHelperText error>{errors.selectedPackage}</FormHelperText>}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Customer Number"
                                fullWidth
                                variant="outlined"
                                size='small'
                                value={customerNumber}
                                onChange={(e) => setCustomerNumber(e.target.value)}
                                helperText={errors.customerNumber}
                                error={!!errors.customerNumber}
                            />
                        </Grid>


                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Customer Name"
                                fullWidth
                                size='small'
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                helperText={errors.name}
                                error={!!errors.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Expiration Date"
                                type="date"
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={expDate}
                                onChange={(e) => setExpDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    min: new Date().toISOString().split('T')[0],
                                }}
                                helperText={errors.expDate}
                                error={!!errors.expDate}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Gift Voucher Code"
                                fullWidth
                                size='small'
                                variant="outlined"
                                value={couponCode}
                                onChange={(e) => setCouponCode(e.target.value)}
                                helperText={errors.couponCode}
                                error={!!errors.couponCode}
                            />
                        </Grid>

                    </Grid>
                    <Grid item xs={12} md={6} >

                        {/* <TextField
    label="Package Details"
    fullWidth
    variant="outlined"
    size='small'
    value={packageDetailss}
    disabled
    // onChange={(e) => setcmPackageDetails(e.target.value)}
    placeholder="e.g., value1, value2, value3"
    helperText={errors.packageDetailss}
    error={!!errors.packageDetailss}
/> */}
                        <p>Package Details</p>
                        <div>
    Product: {packageDetailss && packageDetailss.products && packageDetailss.products.length > 0
        ? packageDetailss.products.join(', ')
        : 'No products available'}
</div>
<div>
    Service: {packageDetailss && packageDetailss.services && packageDetailss.services.length > 0
        ? packageDetailss.services.join(', ')
        : 'No services available'}
</div>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handlecmSubmit}
                        color="primary"
                        variant="contained"
                        fullWidth
                        style={{ padding: '10px', fontSize: '16px', fontWeight: 'bold', background: '#607d8b' }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            {/* {edit gift customer dialog} */}
            <Dialog open={opencmedit} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Update Gift Customer
                    <Button onClick={handleCloseDialog} style={{ color: '#607d8b', fontSize: '34px' }}>&times;</Button>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Select
                                labelId="select-package-label"
                                value={selectedPackageedit ? selectedPackageedit._id : ''}
                                onChange={handlePackageChangeedit}
                                fullWidth
                                variant="outlined"
                            >
                                <MenuItem value="">Select Package</MenuItem>
                                {props.gift.view_all_gift_package.map((giftitem) => (
                                    <MenuItem key={giftitem._id} value={giftitem._id}>
                                        {giftitem.giftName}
                                    </MenuItem>
                                ))}
                            </Select>

                            {errors.selectedGiftName && <FormHelperText error>{errors.selectedGiftName}</FormHelperText>}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Number"
                                fullWidth
                                variant="outlined"
                                value={custophn}
                                onChange={(e) => setcustophn(e.target.value)}
                                helperText={errors.custophn}
                                error={!!errors.custophn}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Customer Full Name"
                                fullWidth
                                variant="outlined"
                                value={customerFullName}
                                onChange={(e) => setCustomerFullName(e.target.value)}
                                helperText={errors.customerFullName}
                                error={!!errors.customerFullName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Package Details"
                                fullWidth
                                variant="outlined"
                                value={detailsOfPackage}
                                // onChange={(e) => setDetailsOfPackage(e.target.value)}
                                disabled
                                placeholder="e.g., value1, value2, value3"
                                helperText={errors.detailsOfPackage}
                                error={!!errors.detailsOfPackage}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Expiration Date"
                                type="date"
                                fullWidth
                                variant="outlined"
                                value={expirationDate}
                                onChange={(e) => setExpirationDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}

                                helperText={errors.expirationDate}
                                error={!!errors.expirationDate}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Customer ID for Update"
                                fullWidth
                                variant="outlined"
                                value={cuponUpdate}
                                onChange={(e) => setcuponUpdate(e.target.value)}
                                helperText={errors.cuponUpdate}
                                error={!!errors.cuponUpdate}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleeditUpdate}
                        color="primary"
                        variant="contained"
                        fullWidth
                        style={{ padding: '10px', fontSize: '16px', fontWeight: 'bold' }}
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
            {/* { delete customer modal} */}

            <Dialog
                open={deltecustom}
                onClose={() => {
                    setdeltecustom(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to Delete Gift?"}
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description">
                        Are you Delete Gift
                    </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setdeltecustom(false)
                    }} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        props.Delete_all_giftCustomer(id, props.login.token);
                        setdeltecustom(false)
                    }} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>


            <ToastContainer />

            <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <div style={{ padding: "10px" }}>
                    <h2>Customer Gift Details</h2>
                    <p><strong>Gift Package  Name:</strong> {selectedPackageedit}</p>

                    <p><strong>Customer Number:</strong> {custophn}</p>
                    <p><strong>Customer Name:</strong> {customerFullName}</p>
                    <p><strong>Package Details:</strong> {detailsOfPackage}</p>
                    <p><strong>Coupon Code:</strong> {cuponUpdate}</p>
                    {/* <p><strong>Timeslot:</strong> {selectedClient.timeslot}</p> */}
                    <p><strong>Expiration Date:</strong> {new Date(expirationDate).toLocaleString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                    })}</p>
                    <center>


                        <button style={{ color: "white", backgroundColor: "#6A8C9F", border: "1px solid #6A8C9F", padding: "10px", margin: "10px", borderRadius: "10px" }} onClick={() => {
                            setIsDialogOpen(false)
                        }}>Close</button>
                    </center>
                </div>
            </Dialog>

            {/* bill dialog */}

            <Dialog open={isBillDialogOpen} onClose={handleCloseBillDialog} maxWidth="sm" fullWidth>
                <DialogTitle>View Bill</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Customer Number"
                                fullWidth
                                variant="outlined"
                                value={custophn}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Customer Name"
                                fullWidth
                                variant="outlined"
                                value={customerFullName}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Select
                                labelId="select-package-label"
                                value={selectedPackageedit ? selectedPackageedit._id : ''}
                                onChange={handlePackageChangeedit}
                                fullWidth
                                variant="outlined"
                                disabled
                            >
                                <MenuItem value="">Select Package</MenuItem>
                                {props.gift.view_all_gift_package.map((giftitem) => (
                                    <MenuItem key={giftitem._id} value={giftitem._id}>
                                        {giftitem.packageDetails}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Price"
                                fullWidth
                                variant="outlined"
                                value={price}

                                // value={billData.price}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                focused
                                label="Discount (%)"
                                fullWidth
                                variant="outlined"
                                value={discount}
                                onChange={handleDiscountChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseBillDialog} color="primary">
                        Close
                    </Button>
                    <Button onClick={handlePreviewClick} color="primary">
                        Preview Bill
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <Dialog open={isPreviewDialogOpen} onClose={() => setPreviewDialogOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Bill Preview</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Customer Number"
                                fullWidth
                                variant="outlined"
                                value={custophn}
                                disabled
                                InputLabelProps={{
                                    shrink: true,  // Ensure label shrinks even when the field is disabled
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Customer Name"
                                fullWidth
                                variant="outlined"
                                value={customerFullName}
                                disabled
                                InputLabelProps={{
                                    shrink: true,  // Ensure label shrinks even when the field is disabled
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Select
                                labelId="select-package-label"
                                value={selectedPackageedit ? selectedPackageedit._id : ''}
                                onChange={handlePackageChangeedit}
                                fullWidth
                                variant="outlined"
                                disabled
                            >
                                <MenuItem value="">Select Package</MenuItem>
                                {props.gift.view_all_gift_package.map((giftitem) => (
                                    <MenuItem key={giftitem._id} value={giftitem._id}>
                                        {giftitem.packageDetails}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Final Price after Discount"
                                fullWidth
                                variant="outlined"
                                value={finalPrice}  // Show the final price with discount
                                disabled
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() =>{ setPreviewDialogOpen(false)
                        setBillDialogOpen(false)
                     } } color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog> */}


            {isPreviewDialogOpen && (
                <Dialog open={isPreviewDialogOpen} onClose={() => setPreviewDialogOpen(false)} maxWidth="sm" fullWidth>
                    <div className="preview-overlay">
                        <div className="preview-content" style={{ marginBottom: "30px" }}>
                            <h2 style={{ fontSize: "20px", margin: "10px 10px" }}>Preview Invoice</h2>
                            <hr className='preview-line' />
                            <div className="invoice-container" style={{ marginTop: "30px" }}>
                                <div className="header">
                                    <div className="company-info" style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                                        <div>
                                            <img src={billlogo} style={{ width: "70px", height: "70px", position: "relative", top: "-20px" }} />
                                        </div>
                                        <div>
                                            <h1 style={{ fontSize: "15px" }}>Hair Destrity</h1>
                                            <p style={{ fontSize: "13px" }}>www.website.com</p>
                                            <p style={{ fontSize: "13px" }}>hello@email.com</p>
                                            <p style={{ fontSize: "13px" }}>+91 00000 00000</p>
                                        </div>
                                    </div>
                                    <div className="invoice-details">
                                        <p style={{ fontSize: "13px" }}>Date: {new Date().toLocaleDateString()}</p>
                                    </div>
                                </div>

                                <table className="invoice-table">
                                    <thead>
                                        <tr>
                                            <th>Item Detail</th>
                                            <th>Qty</th>
                                            <th>Rate</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{selectedPackageedit ? selectedPackageedit.packageDetails : 'No Package Selected'}</td>
                                            <td>1</td>
                                            <td>{price}</td>
                                            <td>{price}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className="total-section">
                                    <p>Subtotal: {price}</p>
                                    <p>Discount ({discount}%): {discountAmount.toFixed(2)}</p>
                                    <p>Total: {finalPrice}</p>
                                </div>
                                <div className="buttons">
                                    <button className="cancel-btn" onClick={() => setPreviewDialogOpen(false)} style={{ backgroundColor: "#fff", color: "black", border: "1px solid #008080" }}>Cancel</button>
                                    <Link to={"/billInvoice"} style={{ backgroundColor: "#6A8C9F", padding: "15px", width: "100px", height: "50px", textAlign: "center", color: "white", textDecoration: "none", borderRadius: "5px" }} onClick={() => {
                                        props.Add_Billinvoice(customerFullName, custophn, selectedPackageedit.packageDetails.join(', '), discount, props.login.token, price)
                                        setPreviewDialogOpen(true);


                                    }} >Save</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}



























<LoaderCon />
              <Snackbar
                open={props.snackbar?.response_received}
                close_snack_bar={props.close_snack_bar}
                message={props.snackbar?.message}
              />

        </div >
    );
}

export default Appointment;
