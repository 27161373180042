import React, { useState, useEffect } from 'react';
import '../Component/ProductService.css';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import { FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import { tr } from 'date-fns/locale';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Grid } from '@mui/material';
import * as XLSX from 'xlsx';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import BASEURL from "../../../config/config"
import { addBulkservice } from '../action';
import PreviewIcon from '@mui/icons-material/Preview';
import Snackbar from '../../../common/snackbar/components/snackbar';
import LoaderCon from '../../../common/loader/containers/loader_cont';
function ProductService(props) {
    const [serviceprice, setserviceprice] = useState("");
    const [productname, setProductname] = useState([]);
    const [bulkformData, setFormDatabulk] = useState([{ product: [], price: '' }]);

    const [isPopupOpen, setPopupOpen] = useState(false);
    const [isPopupOpenproduct, setPopupOpenProduct] = useState(false);
    const [bulkopen, setisproductbulkmodalopen] = useState(false);
    const [bulkopenservice, setbulkopenservice] = useState(false)

    const [data, setData] = useState([]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(firstSheet);

            // Convert JSON data to the desired format
            const formattedData = jsonData.map(item => ({
                product: [item.product],  // Adjust based on your column name
                price: item.price              // Adjust based on your column name
            }));

            setData(formattedData);
        };

        reader.readAsBinaryString(file);
    };

    // const handleSubmitbulk = async () => {
    //     try {
    //         // Assuming `data` contains the uploaded product information from the Excel file.
    //         const productData = data.map(item => ({
    //             product: item.product,
    //             price: item.price
    //         }));

    //         // Dispatch the addBulkProduct action
    //         props.addBulkProduct(productData, props.login.token);

    //         console.log('Data sent successfully.');
    //         setisproductbulkmodalopen(false); // Close the modal
    //     } catch (error) {
    //         console.error('Error sending data:', error.message);
    //     }
    // };
    const handleSubmitbulk = async () => {
        try {
            const response = await axios.post(`${BASEURL.BASEEMP}add-bulk-products`, data, {
                headers: {
                    'Authorization': props.login.token,
                    'Content-Type': 'application/json',
                },
            });

            console.log('Data sent successfully:', response.data);

            // Close the modal
            setisproductbulkmodalopen(false);
            // setbulkopenservice(false);

            // After successful data submission, call the View_All_Products API
            props.View_All_Products(props.login.token);
            setData([])

        } catch (error) {
            console.error('Error sending data:', error.response ? error.response.data : error.message);
            alert('Failed to add products. Please check your data and try again.');
        }
    };




    const handleProductChangebulk = (index, e) => {
        const updatedProducts = [...formData];
        updatedProducts[index].product = e.target.value.split(',');
        setFormDatabulk(updatedProducts);
    };

    const handlePriceChangebulk = (index, e) => {
        const updatedProducts = [...formData];
        updatedProducts[index].price = e.target.value;
        setFormDatabulk(updatedProducts);
    };
    const handleRemoveService = (serviceToRemove) => {
        setFormDataedit((prevData) => ({
            ...prevData,
            servicesedit: prevData.servicesedit.filter(service => service._id !== serviceToRemove._id) // Remove by ID
        }));
    };

    const handleIconClick = () => {
        setPopupOpen(true);
    };

    const handleClosePopup = () => {
        setPopupOpen(false);
    };
    const

        handleChangedata = (e) => {
            const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: value,
            });
        };
    const handlePriceChange = (e) => {
        setserviceprice(e.target.value);
    };
    const handleserviceDelete = async () => {
        try {
            await props.Delete_all_Services(id, props.login.token);
            toast.success("Service deleted successfully");
        } catch (error) {
            toast.error("Failed to delete service");
        } finally {
            setOpen(false);
            setPopupOpen(false);
        }
    };
    const handleServiceChange = (e) => {
        const value = e.target.value;
        // Split the input value by commas and trim any whitespace
        const servicesArray = value.split(',').map(service => service.trim());
        setFormData(prevFormData => ({
            ...prevFormData,
            services: servicesArray
        }));
    };
    const handleProductChange = (e) => {
        const value = e.target.value;

        // Split input by comma, trim spaces, and store in the state as an array
        const productArray = value.split(',').map(product => product.trim());

        setFormData(formData => ({
            ...formData,
            product: productArray,  // Update the product array in state
        }));
    };
    const handleServiceChangeedit = (e) => {
        const value = e.target.value;
        const servicesArray = value.split(',').map(service => service.trim());
        setFormDataedit(prevFormData => ({
            ...prevFormData,
            servicesedit: servicesArray
        }));
    };
    const handleProductChangeedit = (e) => {

        const value = e.target.value;

        const productArray = value.split(',').map(product => product.trim());
        setFormDataedit(formData => ({
            // ...prevFormData,
            ...formData,
            productedit: productArray,
        }));

    };
    const handleSaveserviceChanges = () => {
        // Validation
        if (!formDataedit.servicesedit.length || formDataedit.servicesedit[0] === "") {
            toast.error("Services field cannot be empty");
            return;
        }

        if (!serviceprice || isNaN(serviceprice) || parseFloat(serviceprice) <= 0) {
            toast.error("Please enter a valid price");
            return;
        }

        // Call the update function
        props.Update_All_Service(id, formDataedit.servicesedit, serviceprice, props.login.token);
        setPopupOpen(false);
        toast.success("Service updated successfully");
    };

    useEffect(() => {
        props.View_All_Service(props.login.token);
        props.View_All_Products(props.login.token);
        props.addBulkservice(props.login.token)

        return () => { };
    }, []);
    const [activeTable, setActiveTable] = useState('product');

    // useEffect(() => {
    //     return () => { };
    // }, []);

    console.log(props.viewallservices);
    const [formDataservice, setFormDataservice] = useState({
        category: "",
        subCategory: "",
        services: [],
        price: ""
    });
    const [editformDataservice, formDataserviceedit] = useState({
        category: "",
        subCategory: "",
        name: [],
        price: ""
    });
    console.log(editformDataservice)

    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [customSubCategory, setCustomSubCategory] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState({});

    // Dynamically build category and subcategory options from props.viewallservices
    useEffect(() => {
        const categories = {};
        if (props.viewallservices && props.viewallservices.view_all_service) {
            props.viewallservices.view_all_service.forEach(service => {
                // const subCategories = service.sub_category.map(subCat => subCat.name);
                // categories[service.category] = subCategories;
            });
        }
        setCategoryOptions(categories);
    }, [props.viewallservices]);

    // Handle input change for category
    const handleChangeCategory = (e) => {
        const selectedCategory = e.target.value;
        setFormDataservice({
            ...formDataservice,
            category: selectedCategory,
            subCategory: "" // Reset subcategory on category change
        });

        // Update subcategory options based on selected category
        setSubCategoryOptions(categoryOptions[selectedCategory] || []);
        setCustomSubCategory(false); // Reset custom subcategory input
    };

    // Handle input change for subcategory
    const handleChangeSubCategory = (e) => {
        const selectedSubCategory = e.target.value;
        if (selectedSubCategory === "other") {
            setCustomSubCategory(true);
            setFormDataservice({
                ...formDataservice,
                subCategory: "" // Clear the subcategory when 'Other' is selected
            });
        } else {
            setCustomSubCategory(false);
            setFormDataservice({
                ...formDataservice,
                subCategory: selectedSubCategory
            });
        }
    };

    // Handle custom subcategory input
    const handleCustomSubCategoryChange = (e) => {
        setFormDataservice({
            ...formDataservice,
            subCategory: e.target.value
        });
    };

    // Handle input change for services
    const handleServiceChanges = (event) => {
        const value = event.target.value;
        // Split input by comma, trim whitespace, and filter out empty strings
        const servicesArray = value
            .split(',')
            .map(service => service.trim())      // Trim extra spaces
            .filter(service => service !== '');  // Remove empty values

        setFormDataservice({ ...formDataservice, services: servicesArray });
    };
  
    
    // Handle input change for price
    const handleChangePrice = (event) => {
        const value = event.target.value;
        // Allow only numeric values, including optional negative and decimal points
        if (/^-?\d*\.?\d*$/.test(value)) {
            setFormDataservice({ ...formDataservice, price: value });
        }
    };
    const handleServiceChangesedit = (event) => {
        const { value } = event.target;
        // Assuming service has name and price, set them in the state
        formDataserviceedit({ 
            name: value ,  // Correct way to set an object inside the array
            price: editformDataservice.price // Keeping the price as is
        });
    };
    
    
    const handlePriceChangeedit = (event) => {
        const { value } = event.target;
        formDataserviceedit({ 
             name: editformDataservice.name,  // Preserve the service name
             price: value  // Update the price
        });
    };
    
    
    

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation
        if (!formDataservice.services.length || formDataservice.services[0] === "") {
            toast.error("Service field cannot be empty");
            return;
        }

        if (!formDataservice.price || isNaN(formDataservice.price) || parseFloat(formDataservice.price) <= 0) {
            toast.error("Please enter a valid price");
            return;
        }

        // Format the data to match the required structure
        const formDataservices = {
            category: formDataservice.category || "",
            services: formDataservice.services.map(service => ({
                name: service ? [String(service)] : [""], // Ensure non-empty strings
                price: parseFloat(formDataservice.price) || 0
            }))
        };


        console.log(formDataservices.services)
        // Submit the formatted data using Axios
        try {
            const response = await axios.post(`${BASEURL.BASEEMP}createService`, formDataservices, {
                headers: {
                    'Authorization': props.login.token, // Ensure token is sent in the header
                    'Content-Type': 'application/json',
                },
            });

            console.log('Data sent successfully:', response.data);
            toast.success("Service added successfully");

            // Reset form data after submission
            setFormDataservice({
                category: "",
                subCategory: "",
                services: [],
                price: ''
            });
            setIsModalOpen(false);

            // Optionally call a function to refresh services if needed
            props.View_All_Service(props.login.token);

        } catch (error) {
            console.error('Error sending data:', error.response ? error.response.data : error.message);
            alert('Failed to add services. Please check your data and try again.');
        }
    };
    const handleSubmitedit = async (e) => {
        e.preventDefault();
    
        // Validation: Ensure required fields are not empty, or add your validation logic here
      
    
        // Submit the formatted data using Axios
        try {
            const response = await axios.put(`${BASEURL.BASEEMP}update-Service/${id}`, {
                services: [
                  {
                    name: editformDataservice.name,  // Wrap name in an array
                    price: editformDataservice.price  // Pass the price directly
                  }
                ]
              },
            {
                headers: {
                    'Authorization': props.login.token, // Ensure token is sent in the header
                    'Content-Type': 'application/json',
                },
            });
    
            // Success handling
            console.log('Data sent successfully:', response.data);
            toast.success("Service updated successfully");
    
            // Reset form data after submission
            formDataserviceedit({
                category: "",
                subCategory: "",
                services: [],  // Clear services after submission
                price: ''      // Clear price
            });
            setIsModalOpen(false);
            setPopupOpen(false);
            ModalOpenEdit(false)
    
            // Optionally call a function to refresh services if needed
            props.View_All_Service(props.login.token);
    
        } catch (error) {
            // Error handling
            console.error('Error sending data:', error.response ? error.response.data : error.message);
            toast.error('Failed to update service. Please check your data and try again.');
        }
    };
    


    const [formData, setFormData] = useState({
        product: [],
        services: [],
    });
    const [formDataedit, setFormDataedit] = useState({
        client: '',
        name: '',
        number: '',
        service: '',
        employee: '',
        date: '',
        time: '',
        dob: '',
        message: '',
        price: "",
        category: "",
        servicesedit: [],
        productedit: [],
    });

    const [selectedItem, setSelectedItem] = useState('');

    const handleSelectChange = (event) => {
        setSelectedItem(event.target.value);
    };
    const handleSaveProduct = () => {
        if (!productname.length) {
            toast.error("Product field cannot be empty");
            return;
        }

        if (!proprice || isNaN(proprice) || parseFloat(proprice) <= 0) {
            toast.error("Please enter a valid price");
            return;
        }

        props.Add_Products(productname, proprice, props.login.token);
        setproprice("");
        // setFormData({ product: [] });
        setProductname([])
        toast.success("Product added successfully");
        setisproductmodalopen(false);
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isproductmodalopen, setisproductmodalopen] = useState(false);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState("");
    const [productprice, setProductPrice] = useState("");
    console.log(id)
    const [service, setservice] = useState([]);
    const [price, setprice] = useState("");
    const [products, setproduct] = useState([]);
    const [proprice, setproprice] = useState("");
    const [productopen, setproductopen] = useState(false);

    const openproductdelete = () => {
        setOpen(true);
    };

    const handleClosedelteproduct = () => {
        setOpen(false);
    };

    const handleDeleteproduct = () => {
        props.Delete_all_Products(id, props.login.token);
        handleClosedelteproduct();
    };
    const handleCloseModal = () => {
        // Clear the form data
        setFormData({
            services: [],
            price: ""
        });
        setIsModalOpen(false);
    };
    const handleproductclose = () => {
        setisproductmodalopen(false);
    }
    const handleChangeprice = (e) => {
        setFormData({
            ...formData,
            price: e.target.value,
        });
    };



    const handleSaveChanges = () => {
        if (!formDataedit.productedit.length || formDataedit.productedit[0] === "") {
            toast.error("Product field cannot be empty");
            return;
        }

        if (!productprice || isNaN(productprice) || parseFloat(productprice) <= 0) {
            toast.error("Please enter a valid price");
            return;
        }

        props.UpdateProduct(id, formDataedit.productedit, productprice, props.login.token);
        setPopupOpenProduct(false);
        toast.success("Product updated successfully");
    };

    const handleDelete = () => {
        props.Delete_all_Products(id, props.login.token);
        setproductopen(false);
        toast.success("Product deleted successfully");
    };
    // 


    const handleFileUploadtwo = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);

            // Define the allowed categories
            const allowedCategories = ["men", "women", "kids(boy)", "kids(girl)"];

            // Create a mapping for categories and services
            const categoryMap = {};

            jsonData.forEach((item) => {
                const category = item.Category.toLowerCase().trim(); // Normalize category
                const service = {
                    name: [item['Service Name'].trim()], // Normalize service name
                    price: item.Price
                };

                // Check if the category is allowed
                if (allowedCategories.includes(category)) {
                    // If category doesn't exist, initialize it
                    if (!categoryMap[category]) {
                        categoryMap[category] = {
                            category: category,
                            services: []
                        };
                    }

                    // Push the service into the corresponding category
                    categoryMap[category].services.push(service);
                } else {
                    console.warn(`Invalid category "${category}" found. It has been skipped.`);
                }
            });

            // Convert the object into an array
            const formattedData = Object.values(categoryMap);
            console.log(formattedData)
            // Now you can call the handleSubmittwo function and pass the formattedData
            handleSubmittwo(formattedData);
        };

        reader.readAsArrayBuffer(file);
    };



    // Function to handle file upload

    const handleSubmittwo = async (data) => {
        try {
            const response = await axios.post(`${BASEURL.BASEEMP}add-bulk-services`, data, {
                headers: {
                    'Authorization': props.login.token,
                    'Content-Type': 'application/json',
                },
            });

            console.log('Data sent successfully:', response.data);

            setbulkopenservice(false);
            props.View_All_Service(props.login.token);
            setData([]);

        } catch (error) {
            console.error('Error sending data:', error.response ? error.response.data : error.message);
            alert('Failed to add services. Please check your data and try again.');
        }
    };

    // Function to handle form submission
    const [expandedCategories, setExpandedCategories] = useState({});
    const [isModalOpenEdit, ModalOpenEdit] = useState(false);

    const handleViewMoreClick = (categoryIndex) => {
        // Toggle the category visibility (expand/collapse)
        setExpandedCategories(prevState => ({
            ...prevState,
            [categoryIndex]: !prevState[categoryIndex],
        }));
    };
    return (
        <div className='surround'>
            <div>
                <div className='appointment-text'>
                    <div className='btn-grp' style={{ margin: "0px" }}>
                        <ButtonGroup variant="" >
                            <Button
                                className={activeTable === 'product' ? 'active2' : ''}
                                onClick={() => setActiveTable('product')}
                            >
                                Product
                            </Button>
                            <Button
                                className={activeTable === 'service' ? 'active2' : ''}
                                onClick={() => setActiveTable('service')}
                            >
                                Service
                            </Button>
                        </ButtonGroup>
                    </div>
                    <div className='appointment-button'>
                        {activeTable === 'service' ? <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "30px" }} spacing={2}>
                            <Button className="appointmentopen-modals" onClick={() => {
                                setbulkopenservice(true);

                            }}>
                                Add  Service in Bulk
                            </Button>
                            <Button className="appointmentopen-modals" onClick={() => {
                                setIsModalOpen(true);

                            }}>
                                Add Service
                            </Button>
                        </Grid> :
                            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "30px" }} spacing={2}>
                                <Button className="appointmentopen-modals" onClick={() => {
                                    setisproductbulkmodalopen(true);

                                }}>
                                    Add  Product in Bulk
                                </Button>
                                <Button className="appointmentopen-modals" onClick={() => {
                                    setisproductmodalopen(true);

                                }}>
                                    Add Product
                                </Button>
                            </Grid>

                        }
                        {/*  */}

                    </div>

                </div>
            </div>
            {/* table start */}<br></br>
            {activeTable === 'service' && (
                <div className='appointment-main' id='table1' style={{ maxWidth: "900px" }}>
                    <div className="table-container2">
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Category</th>
                                    <th>Service</th>
                                    <th>Price</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(props.viewallservices.view_all_service) &&
                                    props.viewallservices.view_all_service.map((row, index) => (
                                        <React.Fragment key={index}>
                                            {Array.isArray(row.services) && row.services.length > 0 ? (
                                                <>
                                                    {/* Display only the first service by default */}
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{row.category}</td>
                                                        <td>{row.services[0]?.name}</td>
                                                        <td>{row.services[0]?.price}</td>
                                                        <td>
                                                            <PreviewIcon className="action-icon" style={{ color: 'blue' }} onClick={() => {
                                                                handleIconClick();
                                                                // setId(row._id);
                                                                setFormDataedit({ category: row.category, servicesedit: row.services });
                                                            }} />
                                                            {/* <PreviewIcon className="action-icon" onClick={() => {
                                               
                                               
                                            }} style={{ color: 'red' }} /> */}
                                                        </td>
                                                    </tr>

                                                    {/* Show the rest of the services if expanded */}
                                                    {expandedCategories[index] && row.services.slice(1).map((service, serviceIndex) => (
                                                        <tr key={serviceIndex}>
                                                            <td>{index + 1}</td>
                                                            <td>{row.category}</td>
                                                            <td>{service.name}</td>
                                                            <td>{service.price}</td>
                                                        </tr>
                                                    ))}

                                                    {/* "View More" or "View Less" button */}
                                                    <tr>
                                                        <td colSpan="5" style={{ textAlign: 'center' }}>
                                                            <button onClick={() => handleViewMoreClick(index)}>
                                                                {expandedCategories[index] ? 'View Less' : 'View More'}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </>
                                            ) : (
                                                <tr>
                                                    <td>{row.category}</td>
                                                    <td colSpan={2}>No services available</td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))
                                }
                            </tbody>

                        </table>
                    </div>
                </div>
            )}

            {activeTable === 'product' && (
                <div className='appointment-main' id='table1' style={{ maxWidth: "900px" }}>
                    <div className="table-container2">
                        <table className="styled-table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Product</th>
                                    <th>Price</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(props.viewallservices.view_all_Products) && props.viewallservices.view_all_Products.map((row, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        {/* <td>{row.product}</td> */}
                                        <td>{Array.isArray(row.product) ? (
                                            row.product.map((product, i) => (
                                                <button key={i} className="service-item">
                                                    {product}{" "}
                                                </button>
                                            ))
                                        ) : (
                                            row.service
                                        )}</td>
                                        <td>{row.price}</td>
                                        <td>
                                            <FaEdit className="action-icon" style={{ color: 'green' }} onClick={() => {
                                                // handleIconClick()
                                                // handleIconClick()
                                                setPopupOpenProduct(true)
                                                setId(row._id)
                                                // setproduct(row.product);
                                                setFormDataedit({ productedit: row.product })
                                                setProductPrice(row.price);

                                            }}

                                            />
                                            <FaTrashAlt className="action-icon" onClick={() => {
                                                setId(row._id)
                                                setproductopen(true)
                                            }} style={{ color: 'red' }} />
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            {/* table end */}

            {/* modal */}
            {/* {isModalOpen && (
            )} */}
            <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth="xs" fullWidth>
                <div className="appointment-overlay">
                    <div className="appointment-content">
                        <button className="appointment-close" onClick={handleCloseModal}>
                            &times;
                        </button>
                        <h2>Add Service</h2>
                        <hr className='appointment-line' />
                        <div className="form-container">
                            <form onSubmit={handleSubmit}>

                                {/* Category Input */}
                                <div className="fom-row">
                                    <label>Category</label>
                                    <div className="form-group">
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="category-label"
                                                id="category-select"
                                                value={formDataservice.category}
                                                label="Category"
                                                onChange={handleChangeCategory}
                                            >
                                                <MenuItem value="men">Men</MenuItem>
                                                <MenuItem value="women">Women</MenuItem>
                                                <MenuItem value="kids(boy)">Kids (Boys)</MenuItem>
                                                <MenuItem value="kids(girl)">Kids (Girls)</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </div>
                                </div>


                                {/* Services Input */}
                                <div className="fom-row">
                                    <label>Services</label>
                                    <div className="form-group">
                                        <TextField
                                            name="services"
                                            placeholder="Put Multiple Services Using Comma"
                                            value={(formDataservice.services || []).join(',')}
                                            onChange={handleServiceChanges}
                                            sx={{ width: '100%', height: '40px' }}
                                        />
                                    </div>
                                </div>

                                {/* Price Input */}
                                <div className="appointment-form-name">
                                    <label>Price</label>
                                    <TextField
                                        name="price"
                                        value={formDataservice.price}
                                        onChange={handleChangePrice}
                                        inputProps={{ inputMode: 'numeric', pattern: '-?[0-9]*' }} // Restrict input to numeric values
                                        sx={{ width: '100%', height: '40px' }}
                                    />
                                </div>

                                <br />
                                <div className="appointment-form-button">
                                    <button type="submit" style={{ backgroundColor: '#698c9f' }}>Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog open={isModalOpenEdit} onClose={() => { ModalOpenEdit(false) }} maxWidth="xs" fullWidth>
    <div className="appointment-overlay">
        <div className="appointment-content">
            <button className="appointment-close" onClick={() => { ModalOpenEdit(false) }}>
                &times;
            </button>
            <h2>Edit Service</h2>
            <hr className='appointment-line' />
            <div className="form-container">
                <form onSubmit={handleSubmitedit}>

                    {/* Services Input */}
                    <TextField
                        name="services"
                        placeholder="Enter Service"
                        value={editformDataservice.name}  // Display only the first service value from the array
                        onChange={handleServiceChangesedit}  // Handle service change
                        sx={{ width: '100%', height: '40px' }}
                    />
                    <br />
                    <br />

                    {/* Price Input */}
                    <TextField
                        name="price"
                        value={editformDataservice.price || ''}  // Display the price
                        onChange={handlePriceChangeedit}  // Handle price change
                        inputProps={{ inputMode: 'numeric', pattern: '-?[0-9]*' }}  // Allow only numeric values
                        sx={{ width: '100%', height: '40px' }}
                    />
                    <br />
                    <div className="appointment-form-button">
                        <button type="submit" style={{ backgroundColor: '#698c9f' }}>Save Changes</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</Dialog>

            {/* edit form */}



            {/* {  service delete modal} */}
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to delete this item?"}
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description">
                        Deleting this item is irreversible. Please confirm if you wish to proceed.
                    </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpen(false);
                        
                    }} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleserviceDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                fullScreen

                open={isPopupOpen}
                onClose={() => {
                    setPopupOpen(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                {/* <div className="popup-overlay">
        <div className="popup-content">
            <button className="close-button" style={{ fontSize: '34px' }} onClick={() => setPopupOpen(false)}>
                &times;
            </button>
            <h2></h2>

                <label>Services</label>
            <div className="form-row">
              
            </div>

         

           

           
            <br />
           

        </div>
    </div> */}
                <DialogTitle id="alert-dialog-title">
                    Edit Services
                </DialogTitle>
                <DialogContent>
                    {formDataedit.servicesedit.length > 0 ? (
                        formDataedit.servicesedit.map((service, index) => (
                            <div key={service._id} className="service-item" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <input
                                    type="text"
                                    value={service.name[0]} // Display service name
                                    readOnly // Make input read-only
                                    style={{ flex: '1', marginRight: '10px' }} // Flex for alignment
                                />
                                <input
                                    type="text"
                                    value={service.price} // Display service price
                                    readOnly // Make input read-only
                                    style={{ flex: '1', marginRight: '10px' }} // Flex for alignment
                                />
                                <FaEdit className="action-icon" style={{ color: 'green' }} onClick={() => {

                                    ModalOpenEdit(true)
                                    formDataserviceedit({ 
                                        name: service.name ,  // Correct object structure in an array
                                        price: service.price                // Store the price directly
                                      });
                                      
                                    console.log( service.service)
                                    console.log(service._id)
                                    // handleIconClick()
                                    // handleIconClick()
                                   
                                    setId(service._id)
                                    // setproduct(row.product);
                                   

                                }}

                                />
                                <FaTrashAlt className="action-icon" onClick={() => {
                                    setId(service._id)
                                    setOpen(true);
                                }} style={{ color: 'red' }} />


                              
                            </div>
                        ))
                    ) : (
                        <p>No services available. Please add services.</p>
                    )}
                    {/* <div className="form-group">
                <input
                    type="text"
                    name="services"
                    placeholder="Enter services separated by commas"
                    value={(formDataedit.servicesedit || []).map(service => service.name[0]).join(', ')} // Show comma-separated names
                    onChange={handleServiceChangeedit}
                />
            </div> */}
                </DialogContent>
                {/* <DialogActions>
                <div className="appointment-form-button"> */}
                <center className="appointment-form-button">


                    <button style={{ backgroundColor: '#698c9f' }} onClick={() => {
                        setPopupOpen(false)
                    }}>Cancel</button>
                </center>
                {/* </div> */}
                {/*                   
                    <div className="appointment-form-button">
                <button style={{ backgroundColor: '#698c9f' }} onClick={handleSaveserviceChanges}>Save Changes</button>
            </div> */}
                {/* </DialogActions> */}
            </Dialog>
            {/* productform dialog */}
            <Dialog open={isproductmodalopen} onClose={handleproductclose} maxWidth="sm" fullWidth>
                <div className="appointment-overlay">
                    <div className="appointment-content">
                        <button className="appointment-close" onClick={handleproductclose}>
                            &times;
                        </button>
                        <h2>Add Products</h2>
                        <hr className='appointment-line' />
                        <div className="form-container" style={{ display: "flex", flexDirection: "column", margin: "0px auto" }}>

                            <div className="fo-row" >
                                <label style={{ fontWeight: "600", fontSize: "18px" }}>Product</label>
                                <div className="form-group">

                                    <TextField
                                        value={productname}  // Add single quotes around each product and join with commas

                                        fullWidth
                                        size='small'
                                        onChange={(e) => {
                                            setProductname(e.target.value)
                                        }}
                                        sx={{ height: '40px', width: "500px" }} // Increase width and height
                                        placeholder='Put Product Name'
                                    />
                                </div>
                            </div>

                            <div className="for-row">
                                <div className="appointment-form-name">
                                    <label style={{ fontWeight: "600", fontSize: "18px" }}>Price</label>
                                    <TextField
                                        variant="outlined"
                                        value={proprice}
                                        onChange={(e) => {
                                            setproprice(e.target.value)
                                        }}
                                        pattern="-?[0-9]*"
                                        sx={{ width: '500px', height: '40px' }} // Increase width and height
                                        InputProps={{
                                            style: {
                                                height: '45px', // Increase height of input box
                                            },
                                        }}
                                    />
                                </div>
                            </div>





                            <div className="appointment-form-button" style={{ margin: "20px auto" }}>
                                <button type="submit" style={{ backgroundColor: "#008080" }} onClick={handleSaveProduct}

                                >Save</button>
                            </div>


                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog open={bulkopen} onClose={() => setisproductbulkmodalopen(false)} maxWidth="sm" fullWidth>
                <div className="appointment-overlay">
                    <div className="appointment-content">
                        <button className="appointment-close" onClick={() => setisproductbulkmodalopen(false)}>
                            &times;
                        </button>
                        <h2>Add Bulk Products</h2>
                        <hr className='appointment-line' />

                        <div className="form-container" style={{ display: "flex", flexDirection: "column", margin: "0px auto" }}>

                            {/* Display Uploaded Data (Product and Price) */}
                            {data && data.length > 0 && (
                                <div>
                                    <h3>Uploaded Data:</h3>
                                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                        <thead>
                                            <tr>
                                                <th style={{ border: "1px solid #ddd", padding: "8px" }}>Product</th>
                                                <th style={{ border: "1px solid #ddd", padding: "8px" }}>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>{item.product}</td>
                                                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>{item.price}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                            <div className="for-row" style={{ marginTop: "20px" }}>
                                <label style={{ fontWeight: "600", fontSize: "18px" }}>Upload Excel</label>
                                <input
                                    type="file"
                                    accept=".xlsx, .xls"
                                    onChange={handleFileChange}
                                    style={{ margin: "10px 0" }}
                                />
                            </div>

                            <div className="appointment-form-button" style={{ margin: "20px auto" }}>
                                <button
                                    type="submit"
                                    style={{ backgroundColor: "#008080" }}
                                    onClick={handleSubmitbulk}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog open={bulkopenservice} onClose={() => setbulkopenservice(false)} maxWidth="sm" fullWidth>
                {/* <div className="appointment-overlay">
                    <div className="appointment-content">
                        <button className="appointment-close" onClick={() => setbulkopenservice(false)}>
                            &times;
                        </button>
                        <h2>Add Bulk Services</h2>
                        <hr className='appointment-line' />

                        <div className="form-container" style={{ display: "flex", flexDirection: "column", margin: "0px auto" }}>

                            
                         

                            <div className="for-row" style={{ marginTop: "20px" }}>
                                <label style={{ fontWeight: "600", fontSize: "18px" }}>Upload Excel</label>
                                <input
                                    type="file"
                                    accept=".xlsx, .xls"
                                   
                                    style={{ margin: "10px 0" }}
                                />
                            </div>

                            <div className="appointment-form-button" style={{ margin: "20px auto" }}>
                                <button
                                    type="submit"
                                    style={{ backgroundColor: "#008080" }}
                                   
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}
                bulkOpenService && (
                <div className="appointment-overlay">
                    <div className="appointment-content">
                        <button className="appointment-close" onClick={() => setbulkopenservice(false)}>
                            &times;
                        </button>
                        <h2>Add Bulk Services</h2>
                        <hr className="appointment-line" />
                        <div className="form-container" style={{ display: "flex", flexDirection: "column", margin: "0px auto" }}>
                            <div className="for-row" style={{ marginTop: "20px" }}>
                                <label style={{ fontWeight: "600", fontSize: "18px" }}>Upload Excel</label>
                                <input
                                    type="file"
                                    accept=".xlsx, .xls"
                                    onChange={handleFileUploadtwo}
                                    style={{ margin: "10px 0" }}
                                />
                            </div>
                            <div className="appointment-form-button" style={{ margin: "20px auto" }}>
                                <button type="submit" style={{ backgroundColor: "#008080" }} onClick={handleSubmittwo}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                )
            </Dialog>
            {/* new dialog */}
            <Dialog
                open={productopen}
                onClose={() => {
                    setproductopen(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure you want to delete this item?"}
                </DialogTitle>
                <DialogActions>
                    <button onClick={() => {
                        setproductopen(false)
                    }} className='product-cancel-button'>
                        Cancel
                    </button>
                    <button onClick={handleDelete} color="primary" autoFocus className='product-delete-button'>
                        Delete
                    </button>
                </DialogActions>
            </Dialog>
            {/* edit product */}
            {isPopupOpenproduct && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <button className="close-button" onClick={() => {
                            setPopupOpenProduct(false)
                        }}>
                            &times;
                        </button>
                        <h2>Edit Product</h2>

                        <div className="fom-row">
                            <label>Product</label>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="services"
                                    placeholder="Enter services separated by commas"
                                    // value={formData.services.join(', ')}
                                    // value={products}
                                    value={(formDataedit.productedit || []).join(',')}
                                    onChange={handleProductChangeedit}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="appointment-form-name">
                                <label>Price</label>
                                <input
                                    type="text"
                                    name="price"
                                    placeholder="Price of the service"
                                    value={productprice}
                                    style={{ width: "100%" }}
                                    onChange={(e) => setProductPrice(e.target.value)}
                                    pattern="-?[0-9]*"  // Allows optional "-" followed by digits

                                />
                            </div>
                        </div>
                        <br />
                        <div className="appointment-form-button">
                            <button onClick={handleSaveChanges}>Save Changes</button>

                        </div>

                    </div>
                </div>
            )}
            <ToastContainer />
            <LoaderCon />
              <Snackbar
                open={props.snackbar?.response_received}
                close_snack_bar={props.close_snack_bar}
                message={props.snackbar?.message}
              />
        </div>

    )
}
export default ProductService;