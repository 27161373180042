import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import "./Appointment.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Typography } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Grid,
  Box,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
// import inputlogo from '../../Assets/inputlogo.png';
import { FaEdit, FaEye, FaSearch, FaTrashAlt } from "react-icons/fa";
import { Checkbox, ListItemText } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Snackbar from '../../../common/snackbar/components/snackbar';
import LoaderCon from '../../../common/loader/containers/loader_cont';
function Appointment(props) {
  const [open, setOpen] = useState(false);
  const customers = props.client.view_all_client;
  const [showMessage, setShowMessage] = useState(false);
  const [category, setCategory] = useState("");
  // const [subCategory, setSubCategory] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const timeSlots = [
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
  ];

  const handleViewDetails = (client) => {
    setSelectedClient(client);
    setIsDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedClient(null);
  };
  // Handle button click
  const handleButtonClick = () => {
    setShowMessage(true); // Show the message
  };
  const [client, setclient] = React.useState("");
  const [service, setService] = useState([]);
  const [employee, setemployee] = useState("");
  const [name, setName] = useState("");
  const [dob, setDob] = useState(new Date());
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [appointmentid, setappointmentid] = useState("");
  const [freeslot, Setfreeslot] = useState([]);
  const [findemployee, SetFind] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCustomerview, setSelectedCustomerView] = useState([]);
  const [selectedCustomerAppointments, setSelectedCustomerAppointments] =
    useState([]); // Ensure it's an array
  const [
    selectededitCustomerAppointments,
    setSelectededitCustomerAppointments,
  ] = useState([]); // Ensure it's an array

  const [isModalView, SetisModalView] = useState(false);
  const [isModalViewHistory, SetisModalViewHistory] = useState(false);

  const [btnshow, setOpenbtnshow] = useState(false);
  const [inputValue, setInputValue] = useState("");
  // edit
  const [isModalVieweditHistory, SetisModaleditViewHistory] = useState(false);

  const [id, setid] = useState("");
  const [editbtnshow, setOpeneditbtnshow] = useState(false);

  const [editservice, seteditService] = useState([]);
  const [editemployee, seteditemployee] = useState("");
  const [editname, seteditName] = useState("");
  const [editdob, seteditDob] = useState("");
  const [edittime, seteditTime] = useState("");
  const [editdate, seteditDate] = useState("");
  const [editnumber, seteditNumber] = useState("");
  const [editmessage, seteditMessage] = useState("");
  const [selectededitCustomer, setSelectededitCustomer] = useState(null);
  const [uniqueServicess, setUniqueServices] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [appointments, setAppointments] = useState([
    {
      category: "",
      service: [],
      employee: [],
      date: "",
      time: "",
      message: "",
    },
  ]);
  const [errors, setErrors] = useState({});
  // const handleAppointmentChange = (index, field, value) => {
  //   const newAppointments = [...appointments];
  //   newAppointments[index][field] = value;

  //   // Validate fields whenever there is a change
  //   const fieldErrors = validateFields(newAppointments[index]);
  //   setErrors((prevErrors) => ({ ...prevErrors, [field]: fieldErrors[field] }));

  //   setAppointments(newAppointments);
  // };

  const addAppointment = () => {
    // Limit appointments to a maximum of 5
    if (appointments.length >= 5) {
      alert("You can add a maximum of 5 appointments.");
      return;
    }

    // Check if the last appointment is fully filled before adding a new one
    const lastAppointment = appointments[appointments.length - 1];
    if (
      !lastAppointment.category ||
      !lastAppointment.service.length ||
      !lastAppointment.employee ||
      !lastAppointment.date ||
      !lastAppointment.time ||
      !lastAppointment.message
    ) {
      alert(
        "Please fill in all fields of the current appointment before adding a new one."
      );
      return;
    }

    // Add a new blank appointment if all conditions are met
    const newAppointment = {
      category: "",
      service: [],
      employee: "",
      date: "",
      time: "",
      message: "",
    };
    setAppointments([...appointments, newAppointment]);
    setErrors({}); // Clear errors when adding a new appointment
  };

  const removeAppointment = (index) => {
    // Prevent removing the last remaining appointment
    if (appointments.length === 1) {
      alert("At least one appointment must remain.");
      return;
    }

    // Proceed with removal if conditions are met
    const newAppointments = appointments.filter((_, i) => i !== index);
    setAppointments(newAppointments);
    setErrors({}); // Clear errors when removing an appointment
  };

  const validateFields = () => {
    const newErrors = {};
    appointments.forEach((appointment, index) => {
      const errorsForAppointment = {};
      if (!appointment.category) {
        errorsForAppointment.category = "Category is required.";
      }
      if (!appointment.service || appointment.service.length === 0) {
        errorsForAppointment.service = "At least one service is required.";
      }
      if (!appointment.employee) {
        errorsForAppointment.employee = "Employee is required.";
      }
      if (!appointment.date) {
        errorsForAppointment.date = "Date is required.";
      }
      if (!appointment.time) {
        errorsForAppointment.time = "Time is required.";
      }
      if (!appointment.message) {
        errorsForAppointment.message = "Message is required.";
      }
      if (Object.keys(errorsForAppointment).length > 0) {
        newErrors[index] = errorsForAppointment;
      }
    });
    setErrors(newErrors);
    return newErrors;
  };
  const validateForm = () => {
    let tempErrors = {};

    if (!name.trim()) tempErrors.name = "Name is required.";
    if (!number.trim())
      tempErrors.selectedCustomer = "Customer number is required.";
    // Add custom number validation, e.g., length or pattern
    else if (!/^\d{10}$/.test(number))
      tempErrors.selectedCustomer = "Enter a valid 10-digit customer number.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0; // Return true if no errors
  };

  const handleSubmit = () => {
    const formValid = validateForm(); // Validate form-level fields (name, customer number)
    const fieldsValid = validateFields(); // Validate appointment-specific fields

    if (formValid && Object.keys(fieldsValid).length === 0) {
      // Proceed only if both validations pass
      appointments.forEach((appointment) => {
        props.addnewappointment(
          selectedCustomer,
          name,
          dob,
          appointment.service,
          appointment.date,
          appointment.employee,
          appointment.time,
          appointment.message,
          props.login.token
        );
      });
      resetForm(); // Clear the form after successful submission
    } else {
      alert("Please Put Name and Number Correctly"); // Log error if any validation fails
    }
  };

  const resetForm = () => {
    setName("");
    setDob("");
    setIsModalOpen(false);
    setAppointments([
      {
        category: "",
        service: [],
        employee: "",
        date: "",
        time: "",
        message: "",
      },
    ]);
    setErrors({});
  };
  const handleAppointmentChange = (index, field, newValue) => {
    const updatedAppointments = [...appointments];
    updatedAppointments[index] = {
      ...updatedAppointments[index],
      [field]: newValue,
    };
    setAppointments(updatedAppointments);
  };

  // console.log(uniqueServicess);
  useEffect(() => {
    if (category) {
      const selectedService = props.viewallservices.view_all_service.find(
        (item) => item.category === category
      );
      if (selectedService) {
        setUniqueServices(selectedService.services);
      }
    } else {
      setUniqueServices([]);
    }
  }, [category, props.viewallservices.view_all_service]);

  //   const filterEmployeesByCategory = (category) => {
  //     const availableEmployees = props.allemployee.all_allemployee.filter(
  //       (employee) => employee.category === category
  //     );
  //     setFilteredEmployees(availableEmployees);
  //   };

  const showAllEmployees = () => {
    setFilteredEmployees(props.allemployee.all_allemployee);
  };
  useEffect(() => {
    showAllEmployees();
  }, [props.allemployee.all_allemployee]);
  useEffect(() => {
    if (
      editnumber &&
      customers.find((customer) => customer.customerNumber === editnumber)
    ) {
      setOpeneditbtnshow(true); // Enable button when a valid customer number is selected
    } else {
      setOpeneditbtnshow(false); // Disable button if no valid customer is selected
    }
  }, [editnumber, customers]);

  const handleCustomerSelect = (event, newValue) => {
    if (newValue) {
      const foundCustomer = customers.find(
        (customer) => customer.customerNumber === newValue.customerNumber
      );

      if (foundCustomer) {
        // If customer is found, set their details
        setSelectedCustomer(foundCustomer.customerNumber);
        handleViewDetailshistore(foundCustomer.customerNumber);

        setName(foundCustomer.name);
        setDob(new Date(foundCustomer.dob).toISOString().substr(0, 10)); // Format DOB to YYYY-MM-DD
      } else {
        // If customer is not found, treat it as a new customer
        setSelectedCustomer({ customerNumber: newValue.customerNumber });
        setName(""); // Clear name input
        setDob(""); // Clear DOB input

        // Update the input field with the new customer number
        setNumber(newValue.customerNumber);

        // Create a new customer object
        const newCustomer = {
          customerNumber: newValue.customerNumber,
          name: "",
          dob: "",
        };

        // Add the new customer to the existing customers list
        customers((prevCustomers) => [...prevCustomers, newCustomer]);
      }
    } else {
      // If nothing is selected, clear the inputs but retain the number entered in the input
      setSelectedCustomer(null);
      setName("");
      setDob("");
    }
  };

  // Handle typing in the input field for customer number
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setNumber(inputValue); // Update the number state as the user types
    setSelectedCustomer(inputValue); // Retain the typed number as the selected customer number
  };
  const handleCustomereditSelect = (event, newValue) => {
    if (newValue) {
      const foundCustomer = customers.find(
        (customer) => customer.customerNumber === newValue.customerNumber
      );
      if (foundCustomer) {
        setSelectededitCustomer(foundCustomer.customerNumber);
        // setSelectedCustomerView(foundCustomer);

        handleeditViewDetailshistore(foundCustomer.customerNumber);
        // console.log(`New customer number entered: ${foundCustomer.customerNumber}`);

        seteditName(foundCustomer.name);
        seteditDob(new Date(foundCustomer.dob).toISOString().substr(0, 10));
      } else {
        // Handle case when customer is not found
        // console.log(`New customer number entered: ${newValue.customerNumber}`);
        setSelectededitCustomer({ customerNumber: newValue.customerNumber });
        seteditName(""); // Optionally set a placeholder or default name
        seteditDob(""); // Optionally set a placeholder or default DOB
      }
    } else {
      setSelectededitCustomer(null);
      seteditName("");
      seteditDob("");
    }
  };
  const handleeditViewDetailshistore = (customerNumber) => {
    // Filter appointments for the selected customer number
    const customerAppointments = filteredAppointments.filter(
      (appointment) => appointment.customerNumber === customerNumber
    );
    setSelectededitCustomerAppointments(customerAppointments); // Set the appointments to show in the modal
    // setOpenbtnshow(true); // Open the modal
  };
  const handleViewDetailshistore = (customerNumber) => {
    // Filter appointments for the selected customer number
    const customerAppointments = filteredAppointments.filter(
      (appointment) => appointment.customerNumber === customerNumber
    );
    setSelectedCustomerAppointments(customerAppointments); // Set the appointments to show in the modal
    setOpenbtnshow(true); // Open the modal
  };
  const handleServiceChange = (event) => {
    const value = event.target.value;
    setService(
      // On multiple selection, ensure that value is an array
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleeditServiceChange = (event) => {
    const value = event.target.value;
    seteditService(
      // On multiple selection, ensure that value is an array
      typeof value === "string" ? value.split(",") : value
    );
  };
  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      // props.add_new_product(props.login._id);
      props.viewAllClient(props.login.token);
      props.View_All_Service(props.login.token);
      props.view_all_employee(props.login.token);
      props.view_all_appointmentByDate(props.login.token);
    }
    return () => {
      isCancelled = true;
    };
  }, [props.login._id]);
  // console.log(props.viewallservices.view_all_service);

  const getUniqueServices = () => {
    // const allServices = props.viewallservices.view_all_service.flatMap(
    //   (allservice) => allservice.services
    // );
    // return Array.from(new Set(allServices));
    const allServices = props.viewallservices.view_all_service.flatMap(
      (allservice) => allservice.services.map((service) => service.name)
    );
    return Array.from(new Set(allServices.flat()));
  };
  const uniqueServices = getUniqueServices();
  // console.log("===============================start");

  // console.log(uniqueServices);
  // console.log("===============================end");

  useEffect(() => {
    props.view_all_appointment(props.login.token);
    props.setFreeSlot(freeslot);
    return () => {};
  }, []);
  useEffect(() => {
    // console.log("Edit customer number:", selectededitCustomer);
  }, [selectededitCustomer]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // serach
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Filter appointments based on search term
  const filteredAppointments = Array.isArray(props.appointment.all_appointment)
    ? props.appointment.all_appointment.filter(
        (row) =>
          row.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.dob?.includes(searchTerm) ||
          row.customerNumber?.includes(searchTerm) ||
          row.service?.some(
            (service) =>
              typeof service === "string" &&
              service.toLowerCase().includes(searchTerm.toLowerCase())
          ) ||
          row.date?.includes(searchTerm) ||
          row.timeslot?.includes(searchTerm)
      )
    : [];

  const [fieldErrors, setFieldErrors] = useState({
    service: "",
  });
  console.log(appointments);
  const validateService = () => {
    if (editservice.length === 0) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        service: "At least one service must be selected.",
      }));
      return false;
    } else {
      setFieldErrors((prevErrors) => ({ ...prevErrors, service: "" }));
      return true;
    }
  };
  const serviceOptions = useMemo(() => {
    const allServices = uniqueServicess.flatMap((srv) => srv || {});
    // console.log("All Services:", allServices); // Debug: Log the services list
    return allServices.filter(
      (srv, index, self) => index === self.findIndex((s) => s.name === srv.name)
    ); // Ensure unique services based on 'name'
  }, [uniqueServicess]);
  // console.log(selectedCustomerAppointments);
  return (
    <div className="surround">
      <div>
        <div className="appointment-text">
          <h1>Appointment</h1>
          <div className="appointment-button">
            <Button
              className="appointmentopen-modals"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Add Appointment
            </Button>
          </div>
        </div>
        {/* Search bar */}
        <div className="search-bar">
          <TextField
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
            variant="outlined"
            style={{
              marginBottom: "40px",
              marginLeft: "15px",
              outline: "none",
            }}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                notchedOutline: "notched-outline", // Custom class for the border
              },
            }}
            className="search-input"
          />
        </div>
        {/* Table */}
        <div className="appointment-main">
          <div className="table-container">
            <table className="styled-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                  {/* <th>DOB</th> */}
                  <th>Phone</th>
                  <th>Service</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredAppointments.map((row, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{row.name}</td>
                    {/* <td>{row.dob}</td> */}
                    <td>{row.customerNumber}</td>
                    <td>
                      {Array.isArray(row.service)
                        ? row.service.map((service, i) =>
                            service && service.name ? (
                              <Button
                                key={i}
                                variant="outlined"
                                sx={{
                                  margin: "2px",
                                  borderColor: "#6A8C9F",
                                  color: "#6A8C9F",
                                  "&:hover": {
                                    borderColor: "#004D40",
                                    color: "#004D40",
                                  },
                                }}
                              >
                                {Array.isArray(service.name)
                                  ? service.name.join(", ")
                                  : service.name}{" "}
                                {/* Display the service name(s) */}
                              </Button>
                            ) : null
                          )
                        : row.service}
                    </td>
                    <td>{row.date.split("T")[0]}</td>
                    <td>{row.timeslot}</td>
                    <td>
                      {/* Status Button */}
                      <button
                        style={{
                          color: "white",

                          backgroundColor:
                            row.status === "pending" ? "#FFC72C" : "#6A8C9F", // Adjust color based on status
                          border: "1px solid",
                          padding: "8px 16px",
                          borderRadius: "5px",
                          textTransform: "capitalize",
                        }}
                      >
                        {row.status}
                      </button>
                    </td>
                    <td>
                      <FaEdit
                        onClick={() => {
                          setShowMessage(true);
                          setid(row._id);
                          seteditService(row.service);
                          seteditemployee(row.assignEmployee);
                          seteditName(row.name);
                          seteditDob(row.dob);
                          seteditTime(row.timeslot);
                          seteditDate(row.date.split("T")[0]);
                          seteditNumber(row.customerNumber);
                          seteditMessage(row.message);
                          handleCustomereditSelect(null, {
                            customerNumber: row.customerNumber,
                          });
                        }}
                        className="action-icon"
                        style={{ color: "green" }}
                      />
                      <FaEye
                        onClick={() => handleViewDetails(row)}
                        className="action-icon"
                        style={{ color: "purple" }}
                      />
                      <FaTrashAlt
                        className="action-icon"
                        style={{ color: "red" }}
                        onClick={() => {
                          setOpen(true);
                          setappointmentid(row._id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* modal */}
      <Dialog
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          SetFind(false);
          props.setFreeSlot(freeslot);
          setclient("");
          setService([]);
          setemployee();
          setName("");
          setDob("");
          setTime("");
          setDate("");
          setNumber("");
          setMessage("");
          setSelectedCustomerAppointments([]);
          setOpenbtnshow(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Add Appointment
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => {
              setIsModalOpen(false);
              SetFind(false);
              props.setFreeSlot(freeslot);
              setclient("");
              setService([]);
              setemployee();
              setName("");
              setDob("");
              setTime("");
              setDate("");
              setNumber("");
              setMessage("");
              setSelectedCustomerAppointments([]);
              setOpenbtnshow(false);
            }}
            aria-label="close"
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form>
            <br></br>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  fullWidth
                  freeSolo // Allows the user to input new customer numbers
                  options={customers}
                  getOptionLabel={(option) => option.customerNumber || ""}
                  onChange={handleCustomerSelect} // Handles selection or new input
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      label="Customer Number"
                      placeholder="Search or add customer number"
                      value={number} // The value entered in the input
                      onChange={handleInputChange} // Updates the input field's value
                      error={Boolean(errors.selectedCustomer)} // Show error if necessary
                      helperText={errors.selectedCustomer} // Display error text if any
                    />
                  )}
                />
              </Grid>
              <Grid
                style={{
                  margin: "15px 0px 10px 18px",
                  border: "0.4px solid #ccc",
                }}
              >
                <Button
                  disabled={btnshow === false}
                  style={
                    btnshow === true
                      ? {
                          width: "415px",
                          height: "40px",
                          color: "#6a8c9f",
                          border: "1px solid #6a8c9f",
                        }
                      : {
                          width: "415px",
                          height: "40px",
                          color: "red",
                          border: "1px solid red",
                        }
                  }
                  onClick={() => SetisModalViewHistory(true)}
                >
                  {btnshow === true ? (
                    <Typography>Check History</Typography>
                  ) : (
                    <Typography style={{ fontSize: "8px" }}>
                      Choose Customer Number & Check History
                    </Typography>
                  )}
                </Button>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="DOB"
                  name="dob"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  error={Boolean(errors.dob)}
                  helperText={errors.dob}
                />
              </Grid>

              <Grid container spacing={2} style={{ margin: "3px" }}>
                {appointments.map((appointment, index) => (
                  <React.Fragment key={index}>
                    {/* Category Selection */}

                    {/* Category Selection */}
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <Select
                          displayEmpty
                          size="small"
                          value={appointment.category} // Bind to the selected category
                          onChange={(e) => {
                            setCategory(e.target.value); // Update category state
                            handleAppointmentChange(
                              index,
                              "category",
                              e.target.value
                            ); // Use handleAppointmentChange
                          }}
                          error={Boolean(errors[index]?.category)}
                        >
                          <MenuItem value="">
                            <em>Select Category</em>
                          </MenuItem>
                          {props.viewallservices.view_all_service.map(
                            (service, idx) => (
                              <MenuItem key={idx} value={service.category}>
                                {service.category}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        <FormHelperText style={{ color: "red" }}>
                          {errors[index]?.category}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    {/* Service Selection */}
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <Autocomplete
                          multiple
                          options={serviceOptions} // Now options include both name and price
                          getOptionLabel={(option) => option.name} // Display the service name
                          value={appointment.service} // Bind to the appointment's service
                          onChange={(event, newValue) =>
                            handleAppointmentChange(index, "service", newValue)
                          } // Handle change with objects
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              label="Select Service"
                              placeholder="Search for services"
                              error={Boolean(errors[index]?.service)}
                            />
                          )}
                          renderTags={(selected, getTagProps) =>
                            selected.map((option, index) => (
                              <span
                                key={index}
                                style={{
                                  padding: "2px 6px",
                                  backgroundColor: "#e0e0e0",
                                  borderRadius: "4px",
                                  marginRight: "5px",
                                }}
                                {...getTagProps({ index })}
                              >
                                {option.name}{" "}
                                {/* Display both name and price */}
                              </span>
                            ))
                          }
                          noOptionsText="No services found"
                        />

                        <FormHelperText style={{ color: "red" }}>
                          {errors[index]?.service}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        error={Boolean(errors[index]?.employee)}
                      >
                        <Select
                          value={appointment.employee?.name || ""} // Display employee's name if selected
                          onChange={(e) => {
                            const selectedEmployee = filteredEmployees.find(
                              (emp) => emp.name === e.target.value
                            );
                            handleAppointmentChange(index, "employee", {
                              name: selectedEmployee.name,
                              empId: selectedEmployee._id,
                            });
                          }}
                          size="small"
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            <em>Select Employee</em>
                          </MenuItem>
                          {filteredEmployees.length > 0 ? (
                            filteredEmployees.map((emp, i) => (
                              <MenuItem key={i} value={emp.name}>
                                {emp.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>No Employee Found</MenuItem>
                          )}
                        </Select>
                        <FormHelperText>
                          {errors[index]?.employee}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        size="small"
                        type="date"
                        label="Select Date" // Label as placeholder
                        name="date"
                        InputLabelProps={{ shrink: true }} // Keeps the label above when the field is not empty
                        value={appointment.date} // Binds to the appointment.date state
                        onChange={(e) => {
                          const selectedDate = e.target.value; // Get the selected date
                          handleAppointmentChange(index, "date", selectedDate); // Update state
                          props.slotavailableview(
                            appointment.employee?.empId,
                            selectedDate,
                            props.login.token
                          ); // Call the function to check available slots
                          // console.log(selectedDate, props.login.token); // Log the selected date and token
                          SetisModalView(true); // Set modal view to true
                        }}
                        inputProps={{
                          min: new Date().toISOString().split("T")[0], // Prevent past dates
                        }}
                        error={Boolean(errors[index]?.date)}
                        helperText={errors[index]?.date}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        error={Boolean(errors[index]?.time)}
                      >
                        <Select
                          value={appointment.time}
                          onChange={(e) =>
                            handleAppointmentChange(
                              index,
                              "time",
                              e.target.value
                            )
                          }
                          displayEmpty // This prop allows the placeholder to be displayed
                        >
                          <MenuItem value="" disabled>
                            <em>Select Time</em> {/* Placeholder for Time */}
                          </MenuItem>
                          {timeSlots.map((time, i) => (
                            <MenuItem key={i} value={time}>
                              {time}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{errors[index]?.time}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        rows={2}
                        label="Message"
                        value={appointment.message}
                        onChange={(e) =>
                          handleAppointmentChange(
                            index,
                            "message",
                            e.target.value
                          )
                        }
                        error={Boolean(errors[index]?.message)}
                        helperText={errors[index]?.message}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        onClick={() => removeAppointment(index)}
                        color="secondary"
                      >
                        Remove
                      </Button>
                    </Grid>
                  </React.Fragment>
                ))}

                <Grid item xs={12}>
                  <Button
                    onClick={addAppointment}
                    variant="contained"
                    color="primary"
                  >
                    Add Appointment
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsModalOpen(false);
              SetFind(false);
              props.setFreeSlot(freeslot);
              setclient("");
              setService([]);
              setemployee();
              setName("");
              setDob("");
              setTime("");
              setDate("");
              setNumber("");
              setMessage("");
              setSelectedCustomerAppointments([]);
              setOpenbtnshow(false);
            }}
            style={{ color: "#6a8c9f", border: "1px solid #6a8c9f" }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            style={{ backgroundColor: "#6a8c9f", color: "white" }}
            //           onClick={() => {
            //             const validationErrors = validateFields();
            //             if (Object.keys(validationErrors).length === 0) {
            //               // If no errors, proceed with saving appointments
            //               appointments.forEach((appointment) => {
            //                 props.addnewappointment(
            //                   selectedCustomer,
            //                   name,
            //                   dob,
            //                   appointment.services,
            //                   appointment.date,
            //                   appointment.employee,
            //                   appointment.time,
            //                   appointment.message,
            //                   props.login.token
            //                 );
            //               });

            //               // Reset state after successful save
            //               setSelectedCustomer("");
            // setService([]);
            // setemployee("");
            // setOpenbtnshow(false);
            // setName("");
            // setDob("");
            // setTime("");
            // setDate("");
            // setNumber("");
            // setMessage("");
            // setIsModalOpen(false);
            // SetFind(false);
            // setSelectedCustomerAppointments([]);
            // props.setFreeSlot(freeslot);
            // setAppointments([
            //   {
            //     category: "",
            //     service: [],
            //     employee: "",
            //     date: "",
            //     time: "",
            //     message: "",
            //   },
            // ]);
            //             } else {
            //               // Set errors to display validation messages
            //               setErrors(validationErrors);
            //             }
            //           }}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {isModalView && (
        <Dialog
          open={isModalView}
          onClose={() => {
            SetisModalView(false);
            props.setFreeSlot([]);
          }}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            Check Appointment Time
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => {
                SetisModalView(false);
                props.setFreeSlot([]);
              }}
              aria-label="close"
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={0}>
              <Grid item xs={6} sm={4}>
                {/* <FormControl fullWidth>
                                    <Select
                                        name="service"
                                        style={{ width: "150px" }}
                                        value={serviceview}
                                        onChange={handleServiceChangeview}
                                        displayEmpty
                                        renderValue={(selected) => {
                                            if (!selected) {
                                                return <em>Select Service</em>; // Placeholder text when no value is selected
                                            }
                                            return selected; // Show selected service
                                        }}
                                    >
                                        <MenuItem disabled value="">
                                            <em>Select Service</em>
                                        </MenuItem>
                                        {uniqueServices.map((serviceOption, index) => (
                                            <MenuItem key={index} value={serviceOption}>
                                                <ListItemText primary={serviceOption} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl> */}
              </Grid>
              {/* <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    type="date"
                                    style={{ width: "150px" }}
                                    label="Date"
                                    name="date"
                                    InputLabelProps={{ shrink: true }}
                                    value={date}
                                    onChange={(e) => {
                                        setDate(e.target.value)
                                    }}
                                    inputProps={{
                                        min: new Date().toISOString().split('T')[0],  // Set the minimum date to today
                                    }}
                                />
                            </Grid> */}
              <Grid item xs={12} sm={4}>
                {/* <Button style={{ backgroundColor: "#6a8c9f", color: "white" }}
                                    onClick={() => {
                                        console.log(serviceview,date)
                                        props.setFreeSlot([])
                                        props.slotavailableview(serviceview, date, props.login.token)
                                        setDate("")
                                        setServiceview("")

                                    }}>
                                    Check Your Slot
                                </Button> */}
              </Grid>
            </Grid>
            <div className="appointment-main">
              <div className="table-container">
                <table className="styled-table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      {/* <th>Employee Name</th> */}
                      <th>Date</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Check if all_appointment_slot_view is an array and has items */}
                    {Array.isArray(
                      props.appointment.all_appointment_slot_view
                    ) &&
                    props.appointment.all_appointment_slot_view.length > 0 ? (
                      props.appointment.all_appointment_slot_view.map(
                        (row, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            {/* Access the assignEmployee name */}
                            {/* <td>{row.assignEmployee?.name || 'N/A'}</td> */}
                            {/* Display only the date part, not the time */}
                            <td>{row.date.split("T")[0]}</td>
                            <td>{row.timeslot}</td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          No result found
                        </td>
                      </tr>
                    )}
                  </tbody>
                  {Array.isArray(props.appointment.view_appointment_bydate) &&
                    props.appointment.view_appointment_bydate.map(
                      (row, index) => (
                        <div key={index}>{row.date.split("T")[0]}</div>
                      )
                    )}
                </table>
              </div>
            </div>
            {/* {filteredAppointments.length > 0 ? (
                            filteredAppointments.map((appointmentdate) => (
                                <div key={appointmentdate.id}>
                                    <h4>{appointmentdate.name}</h4>
                                </div>
                            ))
                        ) : (
                            <p>No appointments found for the selected date</p>
                        )} */}
          </DialogContent>
          <DialogActions>
            {/* <Button color="primary"
                            style={{ backgroundColor: "#6a8c9f", color: "white" }}
                            onClick={() => {

                                SetisModalView(false);
                                setIsModalOpen(true);

                            }}>
                            Go To Appointment
                        </Button> */}
          </DialogActions>
        </Dialog>
      )}
      <Dialog
        open={isModalViewHistory}
        onClose={() => {
          SetisModalView(false);
        }}
        maxWidth=""
        fullWidth
      >
        <DialogTitle>
          Previous History for this Customer
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => {
              SetisModalViewHistory(false);
              // props.setFreeSlot([])
            }}
            aria-label="close"
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedCustomerAppointments.length > 0 ? (
            <table className="styled-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>DOB</th>
                  <th>Phone</th>
                  <th>Hair Service</th>
                  <th>Price</th>
                  <th>Assigned Employee</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {selectedCustomerAppointments.map((row, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{row.name}</td>
                    <td>{new Date(row.dob).toISOString().substr(0, 10)}</td>
                    <td>{row.customerNumber}</td>
                    <td>
                      {row.service.map((serviceItem, i) => (
                        <div key={i}>
                          {serviceItem.name.join(", ")}{" "}
                          {/* Join in case there are multiple names */}
                        </div>
                      ))}
                    </td>
                    <td>
                      {row.service.map((serviceItem, i) => (
                        <div key={i}>{`₹${serviceItem.price}`}</div>
                      ))}
                    </td>
                    <td>{row.assignEmployee?.name || "N/A"}</td>
                    <td>{new Date(row.date).toISOString().substr(0, 10)}</td>
                    <td>{row.timeslot}</td>
                    <td>
                      <FaEye
                        onClick={() => handleViewDetails(row)}
                        className="action-icon"
                        style={{ color: "purple", cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No appointments found for this customer</p>
          )}
        </DialogContent>
        <DialogActions>
          {/* <Button color="primary"
                            style={{ backgroundColor: "#6a8c9f", color: "white" }}
                            onClick={() => {

                                SetisModalView(false);
                                setIsModalOpen(true);

                            }}>
                            Go To Appointment
                        </Button> */}
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>Are you sure you want to delete?</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.delete_appointment(appointmentid, props.login.token);
              setOpen(false);
              // notify();
            }}
            variant="contained"
            color="primary"
            sx={{ background: "red" }}
          >
            Yes
          </Button>
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            color="secondary"
            sx={{ background: "green" }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isModalVieweditHistory}
        onClose={() => {
          SetisModaleditViewHistory(false);
        }}
        maxWidth=""
        fullWidth
      >
        <DialogTitle>
          Previous History for this Customer
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => {
              SetisModaleditViewHistory(false);
              // props.setFreeSlot([])
            }}
            aria-label="close"
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectededitCustomerAppointments.length > 0 ? (
            <table className="styled-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>DOB</th>
                  <th>Phone</th>
                  <th>Hair Service</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {selectededitCustomerAppointments.map((row, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{row.name}</td>
                    <td>{new Date(row.dob).toISOString().substr(0, 10)}</td>
                    <td>{row.customerNumber}</td>
                    <td>
                      {Array.isArray(row.service)
                        ? row.service.map((service, i) => (
                            <Button
                              key={i}
                              variant="outlined"
                              sx={{
                                margin: "2px",
                                borderColor: "#6A8C9F",
                                color: "#6A8C9F",
                                "&:hover": {
                                  borderColor: "#004D40",
                                  color: "#004D40",
                                },
                              }}
                            >
                              {service}
                            </Button>
                          ))
                        : row.service}
                    </td>
                    <td>{row.date.split("T")[0]}</td> {/* Date formatted */}
                    <td>{row.timeslot}</td>
                    <td>
                      {" "}
                      <FaEye
                        onClick={() => handleViewDetails(row)}
                        className="action-icon"
                        style={{ color: "purple" }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No appointments found for this customer</p>
          )}
        </DialogContent>
        <DialogActions>
          {/* <Button color="primary"
                            style={{ backgroundColor: "#6a8c9f", color: "white" }}
                            onClick={() => {

                                SetisModalView(false);
                                setIsModalOpen(true);

                            }}>
                            Go To Appointment
                        </Button> */}
        </DialogActions>
      </Dialog>
      {/* <Dialog
                open={showMessage}
                onClose={() => {
                    setShowMessage(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Feature Coming Soon"}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        We are working on it. This feature will be available in a few days.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setShowMessage(false)
                    }} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog> */}

      {selectedClient && (
        <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          maxWidth="sm"
          fullWidth
        >
          <div style={{ padding: "10px" }}>
            <h2>Appointment Details</h2>
            <p>
              <strong>Name:</strong> {selectedClient.name}
            </p>
            <p>
              <strong>Date of Birth:</strong> {selectedClient.dob}
            </p>
            <p>
              <strong>Customer Number:</strong> {selectedClient.customerNumber}
            </p>
            <p>
              <strong>Assigned Employee:</strong>{" "}
              {selectedClient.assignEmployee?.name || "N/A"}
            </p>
            <p>
              <strong>Employee ID:</strong>{" "}
              {selectedClient.assignEmployee?.empId || "N/A"}
            </p>

            <ul>
              {Array.isArray(selectedClient.service) &&
              selectedClient.service.length > 0 ? (
                selectedClient.service.map((serv, index) => (
                  <li key={index}>
                    <strong>Service Name:</strong> {serv.name.join(", ")}
                    <br />
                    <br />
                    <strong>Price:</strong> ₹{serv.price}
                  </li>
                ))
              ) : (
                <li>No services listed</li>
              )}
            </ul>
            <p>
              <strong>Message:</strong> {selectedClient.message}
            </p>
            <p>
              <strong>Timeslot:</strong> {selectedClient.timeslot}
            </p>
            <p>
              <strong>Status:</strong> {selectedClient.status}
            </p>
            <p>
              <strong>Date:</strong>{" "}
              {new Date(selectedClient.date).toLocaleString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
            </p>
            <center>
              <button
                style={{
                  color: "white",
                  backgroundColor: "#6A8C9F",
                  border: "1px solid #6A8C9F",
                  padding: "10px",
                  margin: "10px",
                  borderRadius: "10px",
                }}
                onClick={handleCloseDialog}
              >
                Close
              </button>
            </center>
          </div>
        </Dialog>
      )}
      <Dialog
        open={showMessage}
        onClose={() => {
          setShowMessage(false);
        }}
      >
        <DialogTitle style={{ backgroundColor: "#6a8c9f", color: "white" }}>
          Assign Service and Employee
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <br></br>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  fullWidth
                  size="small"
                  name="name"
                  label="Name"
                  type="text"
                  value={editname}
                  onChange={(e) => seteditName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  disabled
                  fullWidth
                  freeSolo
                  value={
                    customers.find(
                      (customer) => customer.customerNumber === editnumber
                    ) || null
                  }
                  options={customers}
                  getOptionLabel={(option) => option.customerNumber || ""}
                  onChange={handleCustomereditSelect}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Customer Number"
                      placeholder="Search or add customer number"
                      value={editnumber}
                      onChange={(e) => seteditNumber(e.target.value)}
                      error={Boolean(errors.selectedCustomer)}
                      helperText={errors.selectedCustomer}
                      size="small"
                    />
                  )}
                />
              </Grid>

              {/* <Button
                disabled={!editbtnshow}
                style={
                  editbtnshow
                    ? {
                        width: "100%",
                        height: "50px",
                        color: "#6a8c9f",
                        border: "1px solid #6a8c9f",
                        marginTop: "16px",
                      }
                    : {
                        width: "100%",
                        height: "100%",
                        color: "red",
                        border: "1px solid red",
                        marginTop: "16px",
                      }
                }
                onClick={() => SetisModaleditViewHistory(true)}
              >
                {editbtnshow ? (
                  <Typography>Check History</Typography>
                ) : (
                  <Typography style={{ fontSize: "8px" }}>
                    Choose Customer Number & Check History
                  </Typography>
                )}
              </Button> */}
              <Grid container spacing={2} style={{ margin: "3px" }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    size="small"
                    name="dob"
                    label="Date of Birth"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={editdob}
                    onChange={(e) => seteditDob(e.target.value)}
                  />
                </Grid>
                {appointments.map((appointment, index) => (
                  <React.Fragment key={index}>
                    {/* Category Selection */}

                    {/* Category Selection */}
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <Select
                          displayEmpty
                          size="small"
                          value={appointment.category} // Bind to the selected category
                          onChange={(e) => {
                            setCategory(e.target.value); // Update category state
                            handleAppointmentChange(
                              index,
                              "category",
                              e.target.value
                            ); // Use handleAppointmentChange
                          }}
                          error={Boolean(errors[index]?.category)}
                        >
                          <MenuItem value="">
                            <em>Select Category</em>
                          </MenuItem>
                          {props.viewallservices.view_all_service.map(
                            (service, idx) => (
                              <MenuItem key={idx} value={service.category}>
                                {service.category}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        <FormHelperText style={{ color: "red" }}>
                          {errors[index]?.category}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    {/* Service Selection */}
                    <Grid item xs={12}>
                      <FormControl fullWidth size="small">
                        <Autocomplete
                          multiple
                          options={serviceOptions} // Now options include both name and price
                          getOptionLabel={(option) => option.name} // Display the service name
                          value={editservice} // Bind to the editservice state
                          onChange={(event, newValue) => {
                            seteditService(newValue);
                          }} // Correctly handle both addition and removal
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              label="Select Service"
                              placeholder="Search for services"
                              error={Boolean(errors.service)}
                            />
                          )}
                          renderTags={(selected, getTagProps) =>
                            selected.map((option, index) => (
                              <span
                                key={index}
                                style={{
                                  padding: "2px 6px",
                                  backgroundColor: "#e0e0e0",
                                  borderRadius: "4px",
                                  marginRight: "5px",
                                }}
                                {...getTagProps({ index })}
                              >
                                {option.name}
                              </span>
                            ))
                          }
                          noOptionsText="No services found"
                        />
                        <FormHelperText style={{ color: "red" }}>
                          {errors.service}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth size="small">
                        <InputLabel>Assigned Employee</InputLabel>
                        <Select
                          name="employee"
                          value={editemployee.name}
                          onChange={(e) =>
                            seteditemployee({ name: e.target.value })
                          }
                          label="Assigned Employee"
                          error={Boolean(errors.employee)}
                        >
                          {Array.isArray(props.allemployee.all_allemployee) &&
                          props.allemployee.all_allemployee.length > 0 ? (
                            props.allemployee.all_allemployee.map((empname) => (
                              <MenuItem key={empname.name} value={empname.name}>
                                {empname.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>No Employee Found</MenuItem>
                          )}
                        </Select>
                        <FormHelperText>{errors.employee}</FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        size="small"
                        type="date"
                        label="Date Selected"
                        name="date"
                        InputLabelProps={{ shrink: true }}
                        value={editdate}
                        onChange={(e) => {
                          seteditDate(e.target.value);
                          props.slotavailableview(
                            e.target.value,
                            props.login.token
                          );
                          SetisModalView(true);
                        }}
                        inputProps={{
                          min: new Date().toISOString().split("T")[0],
                        }}
                        error={Boolean(errors.date)}
                        helperText={errors.date}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        error={Boolean(errors[index]?.time)}
                      >
                        <InputLabel id={`timeSlot-label-${index}`}>
                          Selected Time
                        </InputLabel>
                        <Select
                          labelId={`timeSlot-label-${index}`}
                          name="timeSlot"
                          onChange={(e) => seteditTime(e.target.value)}
                          displayEmpty
                          value={edittime}
                          renderValue={(selected) =>
                            selected ? selected : "Select a time slot"
                          }
                        >
                          <MenuItem value="" disabled>
                            <em>Selected Time</em>
                          </MenuItem>
                          {timeSlots.map((time, i) => (
                            <MenuItem key={i} value={time}>
                              {time}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{errors[index]?.time}</FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        margin="normal"
                        name="message"
                        label="Message"
                        type="text"
                        multiline
                        rows={3}
                        value={editmessage}
                        onChange={(e) => seteditMessage(e.target.value)}
                      />
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>             
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowMessage(false);
            }}
            color="secondary"
            style={{ color: "#6a8c9f", border: "1px solid #6a8c9f" }}
          >     
            Cancel
          </Button>
          <Button
            color="primary"
            style={{ backgroundColor: "#6a8c9f", color: "white" }}
            onClick={() => {
              if (validateService()) {
                props.updatenewappointment(
                  id,
                  selectededitCustomer,
                  editname,
                  editdob,
                  editservice,
                  editdate,
                  editemployee,
                  edittime,
                  editmessage,
                  props.login.token
                );
                setShowMessage(false);
              }
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <LoaderCon />
              <Snackbar
                open={props.snackbar?.response_received}
                close_snack_bar={props.close_snack_bar}
                message={props.snackbar?.message}
              />
    </div>
  );
}
export default Appointment;
