import React, { useState, useEffect } from 'react';
import './AllClients.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Snackbar from '../../../common/snackbar/components/snackbar';
import LoaderCon from '../../../common/loader/containers/loader_cont';
import { FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';
import SearchIcon from "@mui/icons-material/Search";
const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    // "&:hover": {
    //   backgroundColor: alpha(theme.palette.common.white, 0.25),
    // },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "400px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "400px",
    },
  }));
  
  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#333",
    width: "400px",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`, // Corrected interpolation
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  }));
function Appointment(props) {
  
      
    const [client, setclient] = React.useState('');

    const handleChangeclient = (event) => {
        setclient(event.target.value);
    };
    const [selectedClient, setSelectedClient] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleViewDetails = (client) => {
        setSelectedClient(client);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSelectedClient(null);
    };
    useEffect(() => {
        props.viewAllClient(props.login.token);
        return () => { };
    }, []);

    const [formData, setFormData] = useState({
        client: '',
        name: '',
        number: '',
        service: '',
        employee: '',
        date: '',
        time: '',
        dob: '',
        message: '',
    });

    const handleChangedata = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
    };

  
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
   
 
    return (
        <div className='surround'>
            <div>
                <div className='appointment-text'>
                    <h1>
                        All Clients Details
                    </h1>
                   

                    <div className='appointment-button'>
                        {/* <Button className="appointmentopen-modal" onClick={handleOpenModal}>
                            Add Appointment
                        </Button> */}
                    </div>
                    
                </div>
                <Search
            sx={{
              background: "#2323",
              background: "#2323",
              flex: 3,
              marginRight: 2,
            }}
          >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              onChange={(e)=>props.searchClinet(props.client.view_all_client,e.target.value)}
            />
          </Search>
            </div>
            {/* table start */}
            <div className='appointment-main'>
                <div className="table-container">
                    <table className="styled-table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>DOB</th>
                                <th>Phone</th>
                                <th>Service</th>
                                <th>Date / Time</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(props.client.filtered_model)&&props.client.filtered_model.map((row, index) => {
                                // Format the date
                                const formattedDate = new Date(row.date).toLocaleString('en-US', {
                                    weekday: 'long',   // e.g., "Tuesday"
                                    year: 'numeric',   // e.g., "2024"
                                    month: 'long',     // e.g., "August"
                                    day: 'numeric',    // e.g., "27"
                                    hour: '2-digit',   // e.g., "10"
                                    minute: '2-digit', // e.g., "00"
                                    hour12: true,      // 12-hour format with AM/PM
                                });

                                return (
                                    <tr key={row.id}>
                                        <td>{index + 1}</td>
                                        <td>{row.name}</td>
                                        <td>{row.dob}</td>
                                        <td>{row.customerNumber}</td>
                                        <td>
                                            {Array.isArray(row.service) ? (
                                                row.service.map((service, i) => (
                                                    <button
                                                        style={{
                                                            border: "1px solid #6A8C9F",
                                                            borderRadius: "10px",
                                                            gap: "50px",
                                                            padding: "10px"
                                                        }}
                                                        key={i}
                                                        className="service-item"
                                                    >
                                                        {service}{" "}
                                                    </button>
                                                ))
                                            ) : (
                                                row.service
                                            )}
                                        </td>
                                        <td>{formattedDate}</td> {/* Display the formatted date */}
                                        <td>
                                            <FaEye className="action-icon" onClick={() => handleViewDetails(row)} style={{ color: 'purple' }} />
                                        </td>
                                    </tr>
                                );
                            })}

                        </tbody>
                    </table>
                </div>
            </div>
            {/* table end */}

            {/* modal */}
            {isModalOpen && (
                <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth="sm" fullWidth>
                    <div className="appointment-overlay">
                        <div className="appointment-content">
                            <button className="appointment-close" onClick={handleCloseModal}>
                                &times;
                            </button>
                            <h2>Add Appointment</h2>
                            <hr className='appointment-line' />
                            <div className="form-container">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label>Choose Client</label>
                                            <FormControl sx={{ m: 1, minWidth: 110 }} size="small">
                                                <InputLabel id="demo-select-small-label">Select</InputLabel>
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={client}
                                                    label="Age"
                                                    onChange={handleChangeclient}
                                                    style={{ width: "380px" }}
                                                >
                                                    <MenuItem value={10}>Ten</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="appointment-form-name">
                                            <label>Name</label>
                                            <input
                                                type="text"
                                                name="name"
                                                placeholder="Name of the customer"
                                                value={formData.name}
                                                onChange={handleChangedata}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label>Number</label>
                                            <input
                                                type="text"
                                                name="number"
                                                placeholder="Number of the customer"
                                                value={formData.number}
                                                onChange={handleChangedata}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Select Service</label>
                                            <select name="service" value={formData.service} onChange={handleChangedata}>
                                                <option value="">Select</option>
                                                <option value="service1">Service 1</option>
                                                <option value="service2">Service 2</option>
                                                <option value="service3">Service 3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label>Date</label>
                                            <input
                                                type="date"
                                                name="date"
                                                value={formData.date}
                                                onChange={handleChangedata}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Assign Employee</label>
                                            <select name="employee" value={formData.employee} onChange={handleChangedata}>
                                                <option value="">Select</option>
                                                <option value="employee1">Employee 1</option>
                                                <option value="employee2">Employee 2</option>
                                                <option value="employee3">Employee 3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label>Time</label>
                                            <input
                                                type="time"
                                                name="time"
                                                value={formData.time}
                                                onChange={handleChangedata}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>DOB</label>
                                            <input
                                                type="date"
                                                name="dob"
                                                value={formData.dob}
                                                onChange={handleChangedata}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Message</label>
                                        <textarea
                                            name="message"
                                            placeholder="Guidance"
                                            value={formData.message}
                                            onChange={handleChangedata}
                                            style={{ height: "80px" }}
                                        />
                                    </div>
                                    <div className="appointment-form-button">
                                        <button type="submit">Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}
{selectedClient && (
            <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
            <div style={{ padding: "10px" }}>
                <h2>Client Details</h2>
                <p><strong>Name:</strong> {selectedClient.name}</p>
                <p><strong>Date of Birth:</strong> {selectedClient.dob}</p>
                <p><strong>Customer Number:</strong> {selectedClient.customerNumber}</p>
                <p><strong>Appointment IDs:</strong> {selectedClient.appointmentId.join(', ')}</p>
                <p><strong>Date:</strong> {new Date(selectedClient.date).toLocaleString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                })}</p>
                <p><strong>Email:</strong> {selectedClient.email || "Not provided"}</p>
                <p><strong>Note:</strong> {selectedClient.note || "No notes available"}</p>
        
                <center>
                    <button 
                        style={{
                            color: "white", 
                            backgroundColor: "#6A8C9F", 
                            border: "1px solid #6A8C9F", 
                            padding: "10px", 
                            margin: "10px", 
                            borderRadius: "10px"
                        }} 
                        onClick={handleCloseDialog}
                    >
                        Close
                    </button>
                </center>
            </div>
        </Dialog>
        
            )}
             <LoaderCon />
              <Snackbar
                open={props.snackbar?.response_received}
                close_snack_bar={props.close_snack_bar}
                message={props.snackbar?.message}
              />
        </div>
    )
}
export default Appointment;
