import React, { Component } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: 'your_color_here', // Customize the background color
        },
      },
    },
  },
});

export default class Loader extends Component {
  render() {
    const { loader } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Dialog
          open={loader.open}
          aria-labelledby="Loading..."
        >
          <DialogTitle id="simple-dialog-title" style={{ width: "200px" }}>
            <center>
              {/* Custom CircularProgress */}
              <CircularProgress 
                size={80} // Set size of the circle
                thickness={4} // Set the thickness of the circle
                style={{color:"#6A8C9F"}} // Set the color of the circle (can be "primary", "secondary", or any color code)
              />
            </center>
          </DialogTitle>
        </Dialog>
      </ThemeProvider>
    );
  }
}
