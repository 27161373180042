import { EDIT_ALL_SERVICES,AUTO_DATA_FETCH, GET_ALLGIFT_INVOICE, GET_ALLPRODUCT_INVOICE, VIEW_ALL_BILLINVOICE,GET_ALL_PRICE_BY_NUMBER } from "./constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader } from "../../common/loader/action";
import "firebase/storage";
import "firebase/storage";
import { set_snack_bar } from "../../common/snackbar/action";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
    uploadString,
} from "firebase/storage";
import { VIEW_ALL_CLIENT } from "../AllClients/Constant";
import { VIEW_ALL_PRODUCTS, View_All_Service, VIEW_ALL_SERVICES } from "../ProductService/Constant";
// import {View_All_Service} from '../'




export function View_All_Servicesalon(token) {
    // console.log(token)
    return (dispatch) => {
        dispatch(setLoader());
        // console.log(token);
        return fetch(
            UNIVERSAL.BASEEMP +
            "get-all-service",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("asiillllllllllllla", responseJson);
                if (responseJson.status) {
                    dispatch({
                        type: VIEW_ALL_SERVICES,
                        payload: {
                            data: responseJson.result.data.result,
                            count: responseJson.result.data.result.length,
                        },
                    });
                    dispatch(set_snack_bar(true, "Data View Sucessfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({
                        type: VIEW_ALL_SERVICES,
                        payload: { data: [], count: 0 },
                    });
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function View_All_BillInvoice(token) {
    console.log(token)
    return (dispatch) => {
        dispatch(setLoader());
        console.log(token);
        return fetch(
            UNIVERSAL.BASEEMP +
            "get-all-invoice",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("asiillllllllllllla", responseJson);
                if (responseJson.status) {
                    dispatch({
                        type: VIEW_ALL_BILLINVOICE,
                        payload: {
                            data: responseJson.result.data.result,
                            count: responseJson.result.data.result.length,
                        },
                    });
                    dispatch(set_snack_bar(true, "Data View Sucessfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({
                        type: VIEW_ALL_BILLINVOICE,
                        payload: { data: [], count: 0 },
                    });
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function Add_Billinvoice(name, number, service,  price, token) {
    console.log(name, number, service, price, token)
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEEMP + "createInvoice", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                name: name,
                number: number,
                serviceNamesWithPrices: service,
                finalPrice: price,
                // price: price,
                // gst: "18",
            }),
        })
            .then((response) => response.json())
    

            .then((responseJson) => {
                console.log("hmm asbichi",responseJson);
                if (responseJson.status) {
                    dispatch(View_All_BillInvoice(token));
                    dispatch({
                        type: AUTO_DATA_FETCH,
                        payload: {
                            data: responseJson.result.data.result,
                            count: responseJson.result.data.result.length,
                        },
                    });
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));

            });
    };
}


export function Update_All_Billinvoice(id, name, number, service, discount, token) {
    return (dispatch) => {
        dispatch(setLoader());
        //   console.log(service,price)
        return fetch(UNIVERSAL.BASEEMP + "update-invoice/" + id, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token
            },
            body: JSON.stringify({
                name: name,
                number: number,
                service: service,
                discount: discount,
            }),
        })
            .then((response) => response.json())

            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.status) {
                    dispatch(View_All_BillInvoice(token));

                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}



//   export function View_All_Products(token) {
//     console.log(token)
//     return (dispatch) => {
//       dispatch(setLoader());
//       console.log(token);
//       return fetch(
//         UNIVERSAL.BASEEMP +
//         "get-all-product",
//         {
//           method: "GET",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: token,
//           },
//         }
//       )
//         .then((response) => response.json())
//         .then((responseJson) => {
//           console.log("asiillllllllllllla", responseJson);
//           if (responseJson.status) {
//             dispatch({
//               type: VIEW_ALL_PRODUCTS,
//               payload: {
//                 data: responseJson.result.data.result,
//                 count: responseJson.result.data.result.length,
//               },
//             });
//             dispatch(set_snack_bar(true, "Data View Sucessfully"));
//             dispatch(unsetLoader());
//           } else {
//             dispatch({
//               type: VIEW_ALL_PRODUCTS,
//               payload: { data: [], count: 0 },
//             });
//             dispatch(set_snack_bar(true, responseJson.message));
//             dispatch(unsetLoader());
//           }
//           dispatch(unsetLoader());
//         })
//         .catch((error) => {
//           console.error(error);
//         });
//     };
//   }

export function viewAllClient(token) {
    console.log(token)
    return (dispatch) => {
        dispatch(setLoader());
        console.log(token);
        return fetch(
            UNIVERSAL.BASEEMP +
            "get-all-clients",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("asiillllllllllllla", responseJson);
                if (responseJson.status) {
                    dispatch({
                        type: VIEW_ALL_CLIENT,
                        payload: {
                            data: responseJson.result.data.result,
                            count: responseJson.result.data.result.length,
                        },
                    });
                    dispatch(set_snack_bar(true, "Data View Sucessfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({
                        type: VIEW_ALL_CLIENT,
                        payload: { data: [], count: 0 },
                    });
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


export function View_All_Products(token) {
    console.log(token)
    return (dispatch) => {
        dispatch(setLoader());
        console.log(token);
        return fetch(
            UNIVERSAL.BASEEMP +
            "get-all-product",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("asiillllllllllllla", responseJson);
                if (responseJson.status) {
                    dispatch({
                        type: VIEW_ALL_PRODUCTS,
                        payload: {
                            data: responseJson.result.data.result,
                            count: responseJson.result.data.result.length,
                        },
                    });
                    dispatch(set_snack_bar(true, "Data View Sucessfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({
                        type: VIEW_ALL_PRODUCTS,
                        payload: { data: [], count: 0 },
                    });
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


export function Get_all_invoice_product(token) {
    // console.log(token)
    return (dispatch) => {
        dispatch(setLoader());
        // console.log(token);
        return fetch(
            UNIVERSAL.BASEEMP +
            "get-all-invoice-product",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("asiillllllllllllla", responseJson);
                if (responseJson.status) {
                    dispatch({
                        type: GET_ALLPRODUCT_INVOICE,
                        payload: {
                            data: responseJson.result.data.result,
                            count: responseJson.result.data.result.length,
                        },
                    });
                    dispatch(set_snack_bar(true, "Data View Sucessfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({
                        type: GET_ALLPRODUCT_INVOICE,
                        payload: { data: [], count: 0 },
                    });
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


export function Add_ProductInvoice(name, customerNumber, product, discount, price, gst, token) {
    console.log(name, customerNumber, product, discount, price, token)

    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEEMP + "create-InvoiceProduct", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                name: name,
                customerNumber: customerNumber,
                product: product,
                discount: discount,
                price: price,
                gst: gst,
            }),
        })
            .then((response) => response.json())

            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.status) {
                    dispatch(Get_all_invoice_product(token));
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));

            });
    };
}


export function Get_all_invoice_Gift(token) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(
            UNIVERSAL.BASEEMP +
            "get-all-invoice-gift",
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("asiillllllllllllla", responseJson);
                if (responseJson.status) {
                    dispatch({
                        type: GET_ALLGIFT_INVOICE,
                        payload: {
                            data: responseJson.result.data.result,
                            count: responseJson.result.data.result.length,
                        },
                    });
                    dispatch(set_snack_bar(true, "Data View Sucessfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({
                        type: GET_ALLGIFT_INVOICE,
                        payload: { data: [], count: 0 },
                    });
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


export function Add_GiftInvoice(name, customerNumber, giftName, packageDetails, expDate, couponCode, price, token) {
    console.log(name, customerNumber, giftName, packageDetails, expDate, couponCode, price, token);
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEEMP + "create-InvoiceGift", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                name: name,
                customerNumber: customerNumber,
                giftName: giftName,
                packageDetails: packageDetails,
                expDate: expDate,
                couponCode: couponCode,
                price: price,
            }),
        })
            .then((response) => response.json())

            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.status) {
                    dispatch(Get_all_invoice_Gift(token));
                    dispatch(unsetLoader());
                } else {
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));

            });
    };
}

export function UpdateGiftinvoice(id, giftName, customerNumber, name, packageDetails, expDate, couponCode, price, token) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.EMPLOYEE + "update-gift-invoice/" + id, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                giftName: giftName,
                customerNumber: customerNumber,
                name: name,
                packageDetails: packageDetails,
                expDate: expDate,
                couponCode: couponCode,
                price: price,
            }),
        })
            .then((response) => response.json())

            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.status) {
                    dispatch(Get_all_invoice_Gift(token));
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function UpdateAppoimentSatus(token,appointmentid) {
    console.log(token,appointmentid)
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEEMP + "update-appointment-status" , {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                appointmentIds:appointmentid ,
              
            }),
        })
            .then((response) => response.json())

            .then((responseJson) => {
                console.log(responseJson);
                if (responseJson.status) {
                   
                    dispatch(unsetLoader());
                } else {

                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}


export function getAllAppoinmentByNumber(token,number) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(
            UNIVERSAL.BASEEMP +
            "get-services-price/"+number,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("priceeeeeeeeee", responseJson);
                if (responseJson.status) {
                    dispatch({
                        type: GET_ALL_PRICE_BY_NUMBER,
                        payload: {
                            data: responseJson.result.data.result,
                            count: responseJson.result.data.result.length,
                        },
                    });
                    dispatch(set_snack_bar(true, "Data View Sucessfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({
                        type: GET_ALL_PRICE_BY_NUMBER,
                        payload: { data: [], count: 0 },
                    });
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}


export function clearData(payload){
    return {
        type: GET_ALL_PRICE_BY_NUMBER,
        payload: payload,
    };

}

  